import * as type from "../../actions/actionType";

Object.defineProperty(Array.prototype, "chunk_inefficient", {
  value: function (chunkSize) {
    var array = this;
    return [].concat.apply(
      [],
      array.map(function (elem, i) {
        return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
      })
    );
  },
});

const initState = {
  dataBankPartner: [],
};
const bankReducer = (state = initState, action) => {
  switch (action.type) {
    case type.START_FETCH_BANK_PARTNER:
      return {
        ...state,
        success: null,
        dataBankPartner: [],
        messages: null,
        loading: true,
      };
    case type.SUCCESS_FETCH_BANK_PARTNER:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        primaryBank: action.data.find((item) => item.is_primary === 1),
        dataBankPartner: action.data,
        chukBank: action.data.chunk_inefficient(3),
        loading: false,
      };
    case type.FAILED_FETCH_BANK_PARTNER:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        dataBankPartner: action.data,
        primaryBank: null,
        total: null,
        loading: false,
      };

    case type.START_FETCH_BANK:
      return {
        ...state,
        success: null,
        dataList: [],
        messages: null,
        loading: true,
      };
    case type.SUCCESS_FETCH_BANK:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        dataList: action.data,
        loading: false,
      };
    case type.FAILED_FETCH_BANK:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        dataList: null,
        total: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default bankReducer;
