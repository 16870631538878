import moment from "moment";

const dateFormat = "DD/MM/YYYY";
const monthFormat = "MM/YYYY";

const arrTypeTime = ["week", "year", "month", "date"];
const arrSelectDate = [
  {
    key: "7_days",
    title: "7 Ngày trước",
    value: 7,
    disabled: true,
    result: [
      moment().subtract(7, "days").startOf("day"),
      moment().endOf("day"),
    ],
  },
  {
    key: "14_days",
    title: "14 ngày trước",
    value: 14,
    disabled: true,
    result: [
      moment().subtract(14, "days").startOf("day"),
      moment().endOf("day"),
    ],
  },
  {
    key: "30_days",
    title: "30 ngày trước",
    value: 30,
    disabled: true,
    result: [
      moment().subtract(30, "days").startOf("day"),
      moment().endOf("day"),
    ],
  },
  {
    key: "this_month",
    title: "Tháng này",
    value: 0,
    disabled: true,
    result: [moment().startOf("month"), moment().endOf("day")],
  },
  {
    key: "last_month",
    title: "Tháng trước",
    value: 1,
    disabled: true,
    result: [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  },
  {
    key: "option",
    title: "Tùy chọn",
    value: 0,
    disabled: false,
    result: [],
  },
];
export const SPECIAL_CHARACTER_REGX = /[!@#$%^&*(),.?":{}|<>]/g;
export const ENGLIST_REGX = /^[A-Za-z0-9_.]+$/g;
export const CALCULATEDREF = [
  "weight",
  "height",
  "length",
  "service_id",
  "sender_province_id",
  "sender_district_id",
  "sender_ward_id",
  "receiver_province_id",
  "receiver_district_id",
  "receiver_ward_id",
  "cod_amt",
  "package_no",
  "package_list",
];

export const GENEFORM = [
  "weight",
  "height",
  "length",
  "width",
  "service_id",
  "sender_province_id",
  "sender_district_id",
  "sender_ward_id",
  "receiver_province_id",
  "receiver_district_id",
  "receiver_ward_id",
  "cod_amt",
  "package_no",
  "cargo_value",
  "payment_method",
  "service_id",
];

export const BILL_ZONE_IMPORT = {
  1: {
    province: "receiver_province_name",
    district: "receiver_district_name",
    ward: "receiver_ward_name",
  },
  2: {
    province: "sender_province_name",
    district: "sender_district_name",
    ward: "sender_ward_name",
  },
};

export const IMPORT_RECEIVERS = [
  "receiver_name",
  "receiver_phone",
  "receiver_address",
  "receiver_province_id",
  "receiver_district_id",
  "receiver_ward_id",
  "service_id",
  "weight",
  "cod_amt",
  "cargo_value",
  "is_return_doc",
  "cargo_type_id",
  "cargo_content",
  "package_no",
  "ref_code",
  "payment_method",
  "note",
  "length",
  "width",
  "height",
  "partner_id",
  "sender_name",
  "sender_phone",
  "sender_address",
  "sender_province_id",
  "sender_district_id",
  "sender_ward_id",
  "sender_pay_cod",
  "ip",
  "bill_file_id",
  "is_tran_cod",
  "transport_by",
  "other_fee",
  "other_fee_note",
  "is_packed",
  "expected_date",
  "additional_note",
];
export const IMPORT_SENDERS = [
  "sender_name",
  "sender_phone",
  "sender_address",
  "sender_province_id",
  "sender_district_id",
  "sender_ward_id",
  "service_id",
  "weight",
  "cod_amt",
  "cargo_value",
  "is_return_doc",
  "cargo_type_id",
  "cargo_content",
  "package_no",
  "ref_code",
  "payment_method",
  "note",
  "length",
  "width",
  "height",
  "bill_file_id",
  "is_tran_cod",
  "transport_by",
  "other_fee",
  "other_fee_note",
  "is_packed"
];

export const RESET_FIELDS = [
  "weight",
  "package_no",
  "cargo_value",
  "length",
  "width",
  "height",
  "cod_amt",
  "cargo_type_id",
  "is_return_doc",
  "cargo_content",
  "ref_code",
  "note_add",
];
export const REQUIRE_FIELDS = [
  "sender_name",
  "sender_phone",
  "sender_address",
  "sender_province_id",
  "sender_district_id",

  "receiver_address",
  "receiver_province_id",
  "receiver_district_id",

  "receiver_name",
  "receiver_phone",
  "weight",
  "package_no",
];
export const TWADDRESS = [
  "sender_province_id",
  "sender_district_id",
  "receiver_province_id",
  "receiver_district_id",
];
export const WAITINGFORPICK = 2;
export const NGTTN = 10;
export const NGTTS = 11;
export const NNTTS = 21;
export const NNTTN = 20;
export const IS_RETURN_DOC = {
  true: { value: "Y", content: "Có" },
  false: { value: "N", content: "Không" },
  Y: { value: "Y", content: "Có" },
  N: { value: "N", content: "Không" },
  "": { value: "", content: "" },
};

export const IS_RETURN_SUP = {
  true: { value: "Y", content: "Có" },
  false: { value: "N", content: "Không" },
  Y: { value: "Y", content: "Có" },
  N: { value: "N", content: "Không" },
  "": { value: "", content: "" },
};

export const MIN_COD = 1_000;
export const MAX_COD = 100_000_000;
export const ZERO_COD = 0;

export const TRANSPORT_BY = {
  20558: "Ô tô",
  20556: "Xe máy",
  20600: "Hàng không",
};

export const TRANSPORT_BY_LIST = [
  {id:20556, name:"Xe máy"},
  {id:20558, name:"Ô tô"}
];

export const PACKAGE_NOTE = [
  { name: "Không cho xem hàng", value: 1, label: "show" },
  { name: "Cho xem hàng", value: 2, label: "noshow" },
  { name: "Cho xem hàng nhưng không cho thử hàng", value: 3, label: "shownotry" },
];

const DELETE_STATUS_BILLS = [1, 2, 12, 14, 17];
const PRINT_STATUS_BILLS = [1, 2, 3];
const CANCEL_STATUS = 6;

const Constants = {
  dateFormat,
  monthFormat,
  arrSelectDate,
  arrTypeTime,
  SPECIAL_CHARACTER_REGX,
  ENGLIST_REGX,
  DELETE_STATUS_BILLS,
  PRINT_STATUS_BILLS,
  CANCEL_STATUS,
  MOMENT_FORMAT_VALUE: {
    DATE_MONTH_YEAR: "DD/MM/YYYY",
  },
  MESSAGE_VALIDATE: {
    phone: {
      type: "number",
      placeholder: "Nhập số điện thoại",
      message: "Vui lòng nhập số điện thoại!",
      errorMess: "Số điện thoại chưa đúng định dạng!",
    },
    email: {
      placeholder: "Nhập email",
      message: "Vui lòng nhập email!",
      errorMess: "Email chưa đúng định dạng!",
    },
    password: {
      placeholder: "Nhập password",
      message: "Vui lòng nhập password!",
      atleast: "Mật khẩu tối thiểu tám ký tự",
      character:
        "Mật khẩu chứa ít nhất một chữ cái viết hoa, một chữ cái viết thường, một số và một ký tự đặc biệt",
    },
  },
  PASSWORD_LENGTH: 8,
  PASSWORD: "password",
  PHONE: "phone",
  EMAIL: "email",
  NUMBER: "number",
  SMAILL_LIMIT_PAGE: 10,
  PAGE_DEFAULT: 1,
};

export const queue_name = "NTL_CreateBillFileAuth";

export const AGENCY_STATUS = { name: "Đã nhận hàng", value: 14, span: 2 };
export const FILTER_BILL = [
  { name: "Chưa thành công", value: 1, span: 3 },
  { name: "Đơn nháp", value: 12, span: 2 },
  { name: "Chờ lấy hàng", value: 2, span: 2 },
  { name: "Đã lấy hàng", value: 3, span: 2 },
  { name: "Đang vận chuyển", value: 15, span: 3 },
  { name: "Đang giao hàng", value: 13, span: 2 },
  { name: "Đã giao hàng", value: 4, span: 2 },
  { name: "Đã huỷ", value: 6, span: 2 },
  { name: "Không phát được", value: 7, span: 3 },
  { name: "Đang chuyển hoàn", value: 9, span: 3 },
  { name: "Đang giao hàng hoàn", value: 16, span: 3 },
  { name: "Không lấy được", value: 17, span: 3 },
  { name: "Đã chuyển hoàn", value: 10, span: 2 },
  // { name: "Sự cố", value: 11, span: 2 },
  { name: "Nợ COD", value: -4, is_debt: true, span: 2 },
  { name: "Đã trả COD", value: -5, is_debt: false, span: 2 },
];

export const FILTER_BILL_CHANGE_PAYMENT = [
  { name: "Đã lấy hàng", value: 3, span: 2 },
  { name: "Đang vận chuyển", value: 15, span: 3 },
  { name: "Đang giao hàng", value: 13, span: 2 },
  { name: "Không phát được", value: 7, span: 3 },
];

export const FILTER_D = [
  { name: "D1", value: "D - 1" },
  { name: "D2", value: "D - 2" },
  { name: "D3", value: "D - 3" },
];

export const FILTER_DAY = [
  { name: "Trễ 1 ngày", value: "1" },
  { name: "Trễ 2 ngày", value: "2" },
  { name: "Trễ 3 ngày", value: "3" },
];

export const loaisuco = [
  { value: "CS01", name: "Sự cố nhận hàng" },
  { value: "CS02", name: "Sự cố trả hàng" },
  { value: "CS03", name: "Chuyển hoàn" },
  { value: "CS04", name: "Thái độ/ nghiệp vụ nhân viên" },
  { value: "CS05", name: "Sự cố COD" },
  { value: "CS06", name: "Sự cố kết nối" },
];

export const STT_BILLS_SMS = [
  { name: "Chưa thành công", value: "FAI", color: "#D3D8D3" },
  { name: "Chờ lấy hàng", value: "KCB", color: "#E9D282" },
  { name: "Đã lấy hàng", value: "LPC", color: "#BEE6F2" },
  { name: "Đang vận chuyển", value: "DIT", color: "#E9D282" },
  { name: "Đã giao hàng", value: "FBC", color: "#C5EDEE" },
  { name: "Đã huỷ", value: "GBV", color: "#FFB8B8" },
  { name: "Không phát được", value: "FUD", color: "#D3C9E8" },
  { name: "Đang chuyển hoàn", value: "NRT", color: "#DBB9F7" },
  { name: "Đang giao hàng", value: "EOD", color: "#75A375" },
  { name: "Đã chuyển hoàn", value: "MRC", color: "#B8C0E4" },
];

export const COLOR_STT_BILLS_SMS = {
  FAI: { tagColor: "#D3D8D3", color: "#242112" },
  KCB: { tagColor: "#E9D282", color: "#917528" },
  LPC: { tagColor: "#BEE6F2", color: "#0C7CC6" },
  FBC: { tagColor: "#C5EDEE", color: "#0B9C9A" },
  GBV: { tagColor: "#FFB8B8", color: "#230359" },
  FUD: { tagColor: "#D3C9E8", color: "#8E2BDF" },
  NRT: { tagColor: "#DBB9F7", color: "#D27203" },
  MRC: { tagColor: "#B8C0E4", color: "#B80027" },
  EOD: { tagColor: "#75A375", color: "#B80027" },
};

export const TAGS_SMS = [
  { value: "#ma_van_don", name: "Mã vận đơn", max_length: 11 },
  {
    value: "#trang_thai_don_hang",
    name: "Trạng thái đơn hàng",
    max_length: 11,
  },
  { value: "#nguoi_nhan", name: "Người nhận", max_length: 28 },
  { value: "#sdt_nguoi_nhan", name: "SĐT người nhận", max_length: 10 },
  { value: "#nguoi_gui", name: "Người gửi", max_length: 28 },
  { value: "#sdt_nguoi_gui", name: "SĐT người gửi", max_length: 10 },
  { value: "#ngay_phat", name: "Ngày phát", max_length: 15 },
];

export const FILTER_BILL_PART_2 = [
  { name: "Đang chuyển hoàn", value: 9 },
  { name: "Đã chuyển hoàn", value: 10 },
  { name: "Sự cố", value: 11 },
  { name: "Nợ COD", value: -4, is_debt: true },
  { name: "Đã trả COD", value: -5, is_debt: false },
];

export const COD_FILTER_STATUS = [
  { name: "NTL nợ COD", value: -4, is_debt: "true" },
  { name: "NTL đã trả", value: -5, is_debt: "false" },
];

export const COLOR_STATUS = {
  1: "#fff",
  2: "#917528",
  3: "#0C7CC6",
  4: "#0B9C9A",
  5: "#DC3030",
  6: "#230359",
  7: "#8E2BDF",
  8: "#4C66E1",
  9: "#D27203",
  10: "#B80027",
  11: "#249925",
  12: "#242112",
  13: "#242112",
};

export const STATUS_LEADTIME = {
  "D - 0": { title: "Trễ 1 ngày", color: "#BEE6F2" },
  "D - 1": { title: "Trễ 1 ngày", color: "#FFC000" },
  "D - 2": { title: "Trễ 2 ngày", color: "#F2864A" },
  "D - 3": { title: "Trễ 3 ngày", color: "#EB5757" },
  "D - 4": { title: "Trễ 4 ngày", color: "#EB5757" },
  "D - 5": { title: "Trễ 5 ngày", color: "#EB5757" },
  "D - 6": { title: "Trễ 6 ngày", color: "#EB5757" },
  "D - 7": { title: "Trễ 7 ngày", color: "#EB5757" },
  "D - 8": { title: "Trễ 8 ngày", color: "#EB5757" },
  "D - 9": { title: "Trễ 9 ngày", color: "#EB5757" },
  "D - 10": { title: "Trễ 10 ngày", color: "#EB5757" },
};

export const STATUS_D = {
  "D - 0": "#BEE6F2",
  "D - 1": "#FFC000",
  "D - 2": "#F2864A",
  "D - 3": "#EB5757",
  "D - 4": "#EB5757",
  "D - 5": "#EB5757",
  "D - 6": "#EB5757",
  "D - 7": "#EB5757",
  "D - 8": "#EB5757",
  "D - 9": "#EB5757",
  "D - 10": "#EB5757",
};

export const COLOR_STATUS_CONFIG = {
  DANGCHUYENHOA: { tagColor: "#D3D8D3", color: "#242112" },
  DANGVANCHUYEN: { tagColor: "#b6b9de", color: "#0C1793" },
  CHOLAYHANG: { tagColor: "#E9D282", color: "#917528" },
  DALAYHANG: { tagColor: "#BEE6F2", color: "#0C7CC6" },
  DAGIAOHANG: { tagColor: "#C5EDEE", color: "#0B9C9A" },
  DATRACOD: { tagColor: "#B0DEB0", color: "#DC3030" },
  DAHUY: { tagColor: "#FFB8B8", color: "#230359" },
  KHONGPHATDUOC: { tagColor: "#D3C9E8", color: "#8E2BDF" },
  XACNHANDONHANG: { tagColor: "#0F0F0F", color: "#4C66E1" },
  DANGCHUYENHOAN: { tagColor: "#DBB9F7", color: "#f0e3fb" },
  DACHUYENHOAN: { tagColor: "#B8C0E4", color: "#B80027" },
  SUCO: { tagColor: "#EDCAA2", color: "#249925" },
  DONNHAP: { tagColor: "#DD497E", color: "#249925" },
  DANGGIAOHANG: { tagColor: "#E9D282", color: "#917528" },
  DANGGIAOHANGHOAN: { tagColor: "#fed9c8", color: "#FC824A" },
};

export const ADVANCED_FILTER_LIST = [
  "child_partner",
  "utm_source",
  "s_province_id",
  "s_district_id",
  "s_ward_id",
  "r_province_id",
  "r_district_id",
  "r_ward_id",
];
export const THIRD_SOURE = ["BackEnd", "api", "mobile"];
export const MINIMIZE = "c-sidebar-minimized";

export const SCREEN_ADJUST = [1536, 1440, 1366];

export const PAGE_SIZE_DEFAULT = 20;
export const PAGE_DEFAULT = 1;

export const REVERT_BILL_ALLOW = [3, 15, 7];

export const ACCOUNT_POST = [
  "email",
  "cod_payee",
  "partner_name",
  "phone",
  "tax_code",
];

export const SHORT_CUT_CURRENCY = {
  Bilion: { limit: 999_999_999, k: 1_000_000_000 },
  Milion: { limit: 999_999, k: 1_000_000 },
  Thousand: { limit: 999, k: 1_000 },
};

export const SCROLL_LOAD_RECORD = 50;
export const SERVICE_TYPE = {
  10: "CPN",
  11: "Hỏa tốc",
  12: "Quốc tế",
  20: "Đường bộ",
  21: "MES",
  // 21: "Tiết kiệm",
  22: "Nguyên chuyển",
  23: "Từ kho - Đến kho",
  80: "CPN",
  81: "Hỏa tốc",
  90: "CPN",
  91: "Tiết kiệm",
};
export const SERVICE_TYPE_PRINT = {
  10: {name:"CPN", fontSize: "66px"},
  11: {name:"HỎA TỐC", fontSize: "66px"},
  12: {name:"QUỐC TẾ", fontSize: "66px"},
  20: {name:"ĐƯỜNG BỘ", fontSize: "58px"},
  21: {name:"MES", fontSize: "62px"},
  22: {name:"NGUYÊN CHUYÊN", fontSize: "58px"},
  23: {name:"TỪ KHO - ĐẾN KHO", fontSize: "50px"},

  80: {name:"CPN", fontSize: "66px"},
  81: {name:"HỎA TỐC", fontSize: "66px"},
  90: {name:"CPN", fontSize: "66px"},
  91: {name:"TIẾT KIỆM", fontSize: "62px"},
};
export const PAYMENT_METHOD = {
  10: "10-NGTTN",
  11: "11-NGTTS",
  20: "20-NNTTN",
  21: "21-NNTTS",
};
export const PAYMENT_METHOD_LIST = [
  {
    id: 10,
    name: "10-NGTTN"
  },
  {
    id: 11,
    name:  "11-NGTTS"
  },
  {
    id: 20,
    name: "20-NNTTN"
  },
  // {
  //   id: 21,
  //   name: 
  // },
];
export const REQUEST_ALLOW_STATUS = [3, 15, 7]; // Trạng thái Đã lấy hàng + Không phát được
export const REQUEST_STATUS = {
  A: { title: "Đã duyệt", color: "#2db7f5" },
  R: { title: "Từ chối", color: "#dc3545" },
  P: { title: "Đang duyệt", color: "#87d068" },
};
export const REQUEST_STATUS_FILTER = [
  {
    type: "Approved",
    name: "Đã duyệt",
    value: "A",
  },
  {
    type: "Reject",
    name: "Từ chối",
    value: "R",
  },
  {
    type: "Pending",
    name: "Đang duyệt",
    value: "P",
  },
];
export const REQUEST_TYPE = [
  {
    name: "Yêu cầu hủy giao hàng & chuyển hoàn",
    value: "requireCancel",
  },
  // {
  //   name: "Sửa thông tin nhận hàng",
  //   value: "editReceiver",
  // },
  {
    name: "Sửa tiền COD",
    value: "editCod",
  },
];

export const NTL_COMPLAIN_CONTEXT = {
  pick: [
    {
      name: "Không đến lấy hàng",
      value: 1,
    },
    {
      name: "Lấy hàng chậm",
      value: 2,
    },
    {
      name: "Lấy không hết hàng",
      value: 3,
    },
    {
      name: "Cập nhật sai lý do chậm đơn hàng",
      value: 4,
    },
    {
      name: "Cập nhật chậm/Sai trạng thái đơn hàng",
      value: 5,
    },
    {
      name: "Cập nhật sai cân/Kích thước",
      value: 6,
    },
    {
      name: "Thái độ nhân viên lấy kém, xử lý vấn đề chưa hiệu quả",
      value: 7,
    },
    {
      name: "Lý do khác",
      value: 8,
    },
  ],
  delivery: [
    {
      name: "Hàng hư hỏng, vỡ",
      value: 9,
    },
    {
      name: "Hàng thất lạc, giao thiếu, giao nhầm",
      value: 10,
    },
    {
      name: "Hàng giao chậm",
      value: 11,
    },
    {
      name: "Sai hình thức trung chuyển",
      value: 12,
    },
    {
      name: "Cập nhật chậm/Sai trạng thái đơn hàng",
      value: 13,
    },
    {
      name: "Cập nhật sai lý do chậm đơn hàng",
      value: 14,
    },
    {
      name: "Thái độ nhân viên lấy kém, xử lý vấn đề chưa hiệu quả",
      value: 15,
    },
    {
      name: "Lý do khác",
      value: 16,
    },
  ],
  pending: [
    {
      name: "Shop chưa nhận được hàng",
      value: 17,
    },
    {
      name: "Hàng hỏng vỡ",
      value: 18,
    },
    {
      name: "Cập nhật chậm/Sai trạng thái đơn hàng",
      value: 19,
    },
    {
      name: "Cập nhật sai lý do chậm đơn hàng",
      value: 20,
    },
    {
      name: "Thái độ nhân viên lấy kém, xử lý vấn đề chưa hiệu quả",
      value: 21,
    },
  ],
  carecustomer: [
    {
      name: "CSKH thái độ kém",
      value: 22,
    },
    {
      name: "CSKH hỗ trợ/Xử lý chưa hiệu quả",
      value: 23,
    },
  ],
  cod: [
    {
      name: "Chưa nhận được tiền chuyển khoản",
      value: 24,
    },
    {
      name: "Đối soát thiếu tiền",
      value: 25,
    },
    {
      name: "Thu sai tiền thu hộ",
      value: 26,
    },
  ],
};

export const statusTicket = {
  1: { title: "Mới", color: "rgb(233, 210, 130)", item: 1 },
  2: { title: "Đang xử lý", color: "#EAA900", item: 2 },
  3: { title: "Hoàn tất", color: "#38C173", item: 3 },
  4: { title: "Hoãn", color: "#8E2BDF", item: 4 },
  5: { title: "Chờ duyệt", color: "#4C66E1", item: 5 },
  6: { title: "Hủy", color: "red", item: 6 },
};

export const statusTicketId = [
  { title: "Mới", color: "#808A94", item: 1 },
  { title: "Đang xử lý", color: "#EAA900", item: 2 },
  { title: "Hoàn tất", color: "#38C173", item: 3 },
  { title: "Hoãn", color: "red", item: 4 },
  { title: "Chờ duyệt", color: "#4C66E1", item: 5 },
  { title: "Hủy", color: "red", item: 6 },
];

export const COMPLAIN_TYPE = [
  { name: "Lấy hàng", value: "pick" },
  { name: "Giao hàng", value: "delivery" },
  { name: "Chuyển hoàn", value: "pending" },
  { name: "Chăm sóc khách hàng", value: "carecustomer" },
  { name: "Tiền COD & Đối soát", value: "cod" },
];
export const TICKET_DIFF_CONTENT = {
  "package-no": { name: "Số kiện" },
  cargo_content: { name: "Nội dung hàng hóa" },
  cargo_content_name: { name: "Nội dung hàng hóa" },
  cargo_value: { name: "Giá trị hàng hóa" },
  cod_amt: { name: "Tiền thu hộ (COD)", format: true },
  receiver_phone: { name: "Số điện thoại nhận" },
  receiver_name: { name: "Người nhận" },
  receiver_district_name: { name: "Quận/Huyện nhận" },
  receiver_ward_name: { name: "Phường/xã nhận" },
  receiver_address: { name: "Địa chỉ nhận" },
};
export const DETAIL_BILL_CONFIG = {
  service_name: { title: "Loại dịch vụ", type: "content" },
  dimension_weight: { title: "Trọng lượng quy đổi (kg)", type: "content" },
  weight: { title: "Trọng lượng (kg)", type: "content" },
  length: { title: "Kích thước (cm)", type: "size" },
  is_return_doc: { title: "Chuyển hoàn chứng từ", type: "is_return_doc" },
  is_return_sup: { title: "Thu hồi vật tư", type: "is_return_sup" },
  package_no: { title: "Số kiện", type: "content" },
  cargo_type_name: { title: "Loại hàng hóa", type: "content" },
  cargo_value: { title: "Giá trị hàng hóa", type: "number" },
  created_at: { title: "Ngày tạo", type: "date" },
  expected_at: { title: "Ngày giao dự kiến", type: "date" },
  delivery_at: { title: "Ngày giao", type: "date" },
  bill_status_name: { title: "Trạng thái vận đơn", type: "content" },
  cancel_source: { title: "Nguồn hủy", type: "cancel" },
  utm_source: { title: "Nguồn tạo đơn", type: "content" },
  cargo_content: { title: "Nội dung hàng hóa", type: "cargo" },
  total_fee: { title: "Tổng cước", type: "number", bold: true },
  // delivery_fee: { title: "Phụ phí NCOVID", type: "number" },
  //packing_fee_customer: { title: "Phí đóng kiện", type: "number" },
  //counting_fee_customer: { title: "Phí kiểm đếm", type: "number" },
  packing_fee: { title: "Phí đóng kiện", type: "number" },
  counting_fee: { title: "Phí kiểm đếm", type: "number" },
  main_fee: { title: "Phí vận chuyển", type: "number" },
  insurr_fee: { title: "Phí bảo hiểm", type: "number" },
  remote_fee: { title: "Phí ngoại thành", type: "number" },
  lifting_fee: { title: "Phí nâng hạ", type: "number" },
  // promotion_amount: { title: "Khuyến mãi", type: "number" },
  other_fee: { title: "Phí khác", type: "number" },
  cod_amt: { title: "Tiền thu hộ (COD)", type: "number", bold: true },
  cod_fee: { title: "Phí thu hộ (COD)", type: "number" },
  is_tran_cod: { title: "CK COD", type: "is_return_doc" },
  payment_method_name: { title: "Hình thức thanh toán", type: "content" },
  transport_by: { title: "Yêu cầu giao hàng", type: "transport" },
  note: { title: "Yêu cầu khi giao", type: "note" },
};
//Config hiển thị chi tiết bill cho khách hàng đại lý
export const DETAIL_BILL_CONFIG_AGENCY = {
  service_name: { title: "Loại dịch vụ", type: "content" },
  dimension_weight: { title: "Trọng lượng quy đổi (kg)", type: "content" },
  weight: { title: "Trọng lượng (kg)", type: "content" },
  length: { title: "Kích thước (cm)", type: "size" },
  is_return_doc: { title: "Chuyển hoàn chứng từ", type: "is_return_doc" },
  is_return_sup: { title: "Thu hồi vật tư", type: "is_return_sup" },
  package_no: { title: "Số kiện", type: "content" },
  cargo_type_name: { title: "Loại hàng hóa", type: "content" },
  cargo_value: { title: "Giá trị hàng hóa", type: "number" },
  created_at: { title: "Ngày tạo", type: "date" },
  expected_at: { title: "Ngày giao dự kiến", type: "date" },
  delivery_at: { title: "Ngày giao", type: "date" },
  bill_status_name: { title: "Trạng thái vận đơn", type: "content" },
  cancel_source: { title: "Nguồn hủy", type: "cancel" },
  utm_source: { title: "Nguồn tạo đơn", type: "content" },
  cargo_content: { title: "Nội dung hàng hóa", type: "cargo" },
  main_fee_no_discount: { title: "Tổng cước", type: "number", bold: true },
  total_fee: { title: "Tổng cước sau CK", type: "number", bold: true },
  // delivery_fee: { title: "Phụ phí NCOVID", type: "number" },
  //packing_fee_customer: { title: "Phí đóng kiện", type: "number" },
  //counting_fee_customer: { title: "Phí kiểm đếm", type: "number" },
  packing_fee: { title: "Phí đóng kiện", type: "number" },
  counting_fee: { title: "Phí kiểm đếm", type: "number" },
  main_fee: { title: "Phí vận chuyển", type: "number" },
  insurr_fee: { title: "Phí bảo hiểm", type: "number" },
  remote_fee: { title: "Phí ngoại thành", type: "number" },
  lifting_fee: { title: "Phí nâng hạ", type: "number" },
  // promotion_amount: { title: "Khuyến mãi", type: "number" },
  other_fee: { title: "Phí khác", type: "number" },
  cod_amt: { title: "Tiền thu hộ (COD)", type: "number", bold: true },
  cod_fee: { title: "Phí thu hộ (COD)", type: "number" },
  is_tran_cod: { title: "CK COD", type: "is_return_doc" },
  payment_method_name: { title: "Hình thức thanh toán", type: "content" },
  transport_by: { title: "Yêu cầu giao hàng", type: "transport" },
  note: { title: "Yêu cầu khi giao", type: "note" },
};
export const ALLOW_EDIT_BILL = [1, 2, 14];
export const CARGO_VALUE_CONFIG = 12;
export const PARTNER_ID = "id";
export const ERROR_EXCEL = "[ERROR]";
export const OVER_CEILING_CARGO = "Giá trị hàng hóa vượt hạn mức";
export const ERROR_SERVICE = "Lỗi cấu hình dịch vụ";
export const BILL_CONFIG_NAME = {
  webhook: "Cáu hình Webhook",
  service_code: "Loại hình dịch vụ",
  payment_method: "Hình thức thanh toán",
};
export const CARGO_VALUE_SETTING = 12;
export const SETTING_ID = "setting_id";
export const CONFIG_SERVICE_BILL = {
  allow_apart: {
    setting_id: 24,
    setting_code: "allow_apart",
    setting_name: "Giao hàng một phần",
    disabled: false,
    hide: true,
  },
  undelivery_cash: {
    setting_id: 27,
    setting_code: "undelivery_cash",
    setting_name: "Giao hàng không thành công vẫn thu tiền",
    disabled: false,
    hide: true,
  },
};

export const SERVICE_LIST = [
    {
      service_name: "CPN",
      id: 10,
    },
    {
      service_name: "Hỏa tốc",
      id: 11,
    },
    {
      service_name: "Đường bộ",
      id: 20,
    },
    {
      service_name: "MES",
      id: 21,
    },
  ];

export const BILLCONFIG = {
  service_code: [
    {
      setting_desc: "Đường bộ",
      setting_value: 20,
    },
    {
      setting_desc: "CPN",
      setting_value: 10,
    },
    {
      setting_desc: "Tiết kiệm",
      setting_value: 21,
    },
    {
      setting_desc: "Hỏa tốc",
      setting_value: 11,
    },
  ],
  payment_method: [
    { setting_desc: "Người gửi thanh toán sau", setting_value: 11 },
    { setting_desc: "Người gửi thanh toán ngay", setting_value: 10 },
    { setting_desc: "Người nhận thanh toán ngay", setting_value: 20 },
    { setting_desc: "Người nhận thanh toán sau", setting_value: 21 },
  ],
};
export const CHECK_BOX_SURVEY_COL_1 = [
  {
    id: "feed_great",
    value: 8,
    name: "Tất cả đều tuyệt vời",
  },
  {
    id: "feed_delivery",
    value: 10,
    name: "Lấy hàng nhanh",
  },
  {
    id: "feed_delivery_good",
    value: 12,
    name: "Nhân viên lấy hàng thân thiện",
  },
  {
    id: "feed_delivery_ent",
    value: 14,
    name: "Nhân viên giao hàng nhiệt tình",
  },
];
export const CHECK_BOX_SURVEY_COL_2 = [
  {
    id: "cs_good",
    value: 9,
    name: "CSKH hỗ trợ nhanh và nhiệt tình",
  },
  {
    id: "cs_effect",
    value: 11,
    name: "Nhân viên bưu cục hỗ trợ hiệu quả",
  },
  {
    id: "cs_fast",
    value: 13,
    name: "Giao hàng nhanh",
  },
  {
    id: "cs_pay_good",
    value: 15,
    name: "Nhân viên trả hàng tốt",
  },
];

export const COL_DESTRUCTIVE_1 = [
  {
    id: "cs_not_effect",
    name: "CSKH hỗ trợ và xử lý kém hiệu quả",
    value: 1,
  },
  {
    id: "delivery_slow",
    name: "Lấy/Giao/Trả chậm",
    value: 2,
  },
  {
    id: "post_not_good",
    name: "Dịch vụ bưu cục chưa tốt",
    value: 3,
  },
  {
    id: "employee_attidue",
    name: "Thái độ nhân viên chưa tốt",
    value: 4,
  },
];
export const COL_DESTRUCTIVE_2 = [
  {
    id: "support_bad",
    name: "Hỗ trợ chưa nhiệt tình",
    value: 5,
  },
  {
    id: "goods_damage",
    name: "Hàng bị hỏng, vỡ, thất lạc",
    value: 6,
  },
  {
    id: "wrong_information",
    name: "Cập nhật sai thông tin",
    value: 7,
  },
];

export const typeTrouble = [
  { value: "all", title: "Tất cả" },
  { value: "CS01", title: "Sự cố nhận hàng" },
  { value: "CS02", title: "Sự cố trả hàng" },
  { value: "CS03", title: "Chuyển hoàn" },
  { value: "CS04", title: "Thái độ/ nghiệp vụ nhân viên" },
  { value: "CS05", title: "Sự cố COD" },
  { value: "CS06", title: "Sự cố kết nối" },
];

export const rendertypeTrouble = {
  CS01: { value: "CS01", title: "Nhận hàng" },
  CS02: { value: "CS02", title: "Trả hàng" },
  CS03: { value: "CS03", title: "Chuyển hoàn" },
  CS04: { value: "CS04", title: "Thái độ/ nghiệp vụ nhân viên" },
  CS05: { value: "CS05", title: "Sự cố COD" },
  CS06: { value: "CS06", title: "Sự cố kết nối" },
};

export const renderLog = {
  CS01: { key: 1, value: "CS01", title: "Mới tạo" },
  CS02: { key: 2, value: "CS02", title: "Xử lý hoàn tất" },
  CS03: { key: 3, value: "CS03", title: "Hủy bởi CS" },
  CS04: { key: 4, value: "CS04", title: "Từ chối với OPS" },
  CS05: { key: 5, value: "CS05", title: "Đang xử lý" },
  CS06: { key: 6, value: "CS06", title: "Đã đóng" },
  CS07: { key: 7, value: "CS07", title: "OPS không xử lý" },
};

export const statusTicketCS = [
  { title: "Tất cả", key: 5 },
  { title: "Mới", key: "CS01" },
  { title: "Đã xác nhận", key: "CS02" },
  { title: "Hủy", key: "CS03" },
  { title: "Từ chối", key: "CS04" },
  { title: "Đang xử lí", key: "CS05" },
  { title: "Đóng", key: "CS06" },
];

export const renderStatusTicketCS = {
  CS01: { title: "Mới", key: "CS01" },
  CS02: { title: "Đã xác nhận", key: "CS02" },
  CS03: { title: "Hủy", key: "CS03" },
  CS04: { title: "Từ chối", key: "CS04" },
  CS05: { title: "Đang xử lí", key: "CS05" },
  CS06: { title: "Đóng", key: "CS06" },
};

export const RATE_DESC = ["Cực tệ", "Tệ", "Bình thường", "Tốt", "Tuyệt vời"];
export const OBJ_EXTRA = {
  1: "CSKH hỗ trợ và xử lý kém hiệu quả",
  2: "Lấy/Giao/Trả chậm",
  3: "Dịch vụ bưu cục chưa tốt",
  4: "Thái độ nhân viên chưa tốt",
  5: "Hỗ trợ chưa nhiệt tình",
  6: "Hàng bị hỏng, vỡ, thất lạc",
  7: "Cập nhật sai thông tin",
  8: "Tất cả đều tuyệt vời",
  10: "Lấy hàng nhanh",
  12: "Nhân viên lấy hàng thân thiện",
  14: "Nhân viên giao hàng nhiệt tình",
  9: "CSKH hỗ trợ nhanh và nhiệt tình",
  13: "Giao hàng nhanh",
  11: "Nhân viên bưu cục hỗ trợ hiệu quả",
  15: "Nhân viên trả hàng tốt",
};
export const NTL = "NTL";
export const BackEnd_NTL = "BackEnd_NTL";
export const POINT_AVERAGE = 3;
export const LARGE_SCREEN = 1900;
export const SUCCESS_NOTI_FEEDBACK = "Cám ơn bạn đã đánh giá";
export const TITLE_FEEDBACK_FORM =
  "Bạn đánh giá thế nào chất lượng dịch vụ của Nhất Tín ?";
export const FEEDBACK_SERVICE_TITLE = "Đánh giá chất lượng dịch vụ";
export const WARNING_NOTI = ` Đề nghị Quý khách hàng lấy hóa đơn dịch vụ trước ngày mùng 5 của
tháng tiếp theo. Sau thời hạn trên. Nhất Tín Logistics sẽ khai với
cơ quan thuế dưới hình thức “Người mua không lấy hóa đơn” và từ chối
xuất hóa đơn cho khách hàng trừ trường hợp khách hàng đã ký hợp đồng
hoặc có thỏa thuận khác.`;
export const MARQUEE_SPEED = 120;

export const NEW_CPN = 80;
export const NEW_CPN_MES = 90;

export const MIEN_BAC = [
  97, 99, 11, 27, 23, 34, 16, 90, 38, 28, 89, 25, 12, 24, 18, 37, 35, 19, 14,
  26, 17, 20, 36, 36, 22, 88, 21, 29, 30
];
export const MIEN_TRUNG = [77, 78, 73, 92, 76, 74, 75, 43];
export const MIEN_NAM = [
  67, 94, 72, 71, 61, 93, 86, 69, 47, 48, 60, 66, 81, 95, 79, 68, 82, 49, 62,
  85, 83, 70, 63, 65, 84, 64, 50
];

export const ZONE = [{
  id: 1,
  list: MIEN_BAC
},{
  id: 2,
  list: MIEN_TRUNG
},{
  id: 3,
  list: MIEN_NAM
}];

export const CHUYEN_TUYEN_CPN = [
  [75 ,29],
  [29 ,75],
  [29 ,43],
  [43 ,29],
  [50 ,29],
  [29 ,50],
  [50 ,75],
  [75 ,50],
  [43 ,50],
  [50 ,43],

  // [99 ,79],
  // [97 ,79],
  // [11 ,79],
  // [27 ,79],
  // [23 ,79],
  // [34 ,79],
  // [16 ,79],
  // [90 ,79],
  // [38 ,79],
  // [28 ,79],
  // [89 ,79],
  // [25 ,79],
  // [12 ,79],
  // [24 ,79],
  // [18 ,79],
  // [37 ,79],
  // [35 ,79],
  // [19 ,79],
  // [14 ,79],
  // [26 ,79],
  // [17, 79],
  // [20, 79],
  // [36 ,79],
  // [22 ,79],
  // [88 ,79],
  // [21 ,79],
  // [29 ,79],
  // [30 ,79],
  
  [79, 99],
  [79, 97],
  [79, 11],
  [79, 27],
  [79, 23],
  [79, 34],
  [79, 16],
  [79, 90],
  [79, 38],
  [79, 28],
  [79, 89],
  [79, 25],
  [79, 12],
  [79, 24],
  [79, 18],
  [79, 37],
  [79, 35],
  [79, 19],
  [79, 14],
  [79, 26],
  [79, 17],
  [79, 20],
  [79, 36],
  [79, 22],
  [79, 88],
  [79, 21],
  [79, 29],
  [79, 30],
];

export const FLY_CPN = [
  [75 ,29],
  [29 ,75],
  [29 ,43],
  [43 ,29],
  [50 ,29],
  [29 ,50],
  [50 ,75],
  [75 ,50],
  [43 ,50],
  [50 ,43],

  // [99 ,79],
  // [97 ,79],
  // [11 ,79],
  // [27 ,79],
  // [23 ,79],
  // [34 ,79],
  // [16 ,79],
  // [90 ,79],
  // [38 ,79],
  // [28 ,79],
  // [89 ,79],
  // [25 ,79],
  // [12 ,79],
  // [24 ,79],
  // [18 ,79],
  // [37 ,79],
  // [35 ,79],
  // [19 ,79],
  // [14 ,79],
  // [26 ,79],
  // [17, 79],
  // [20, 79],
  // [36 ,79],
  // [22 ,79],
  // [88 ,79],
  // [21 ,79],
  // [29 ,79],
  // [30 ,79],
  
  [79, 99],
  [79, 97],
  [79, 11],
  [79, 27],
  [79, 23],
  [79, 34],
  [79, 16],
  [79, 90],
  [79, 38],
  [79, 28],
  [79, 89],
  [79, 25],
  [79, 12],
  [79, 24],
  [79, 18],
  [79, 37],
  [79, 35],
  [79, 19],
  [79, 14],
  [79, 26],
  [79, 17],
  [79, 20],
  [79, 36],
  [79, 22],
  [79, 88],
  [79, 21],
  [79, 29],
  [79, 30],
];

export default Constants;