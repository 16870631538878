export default {
  TOKEN: localStorage.getItem("token"),
  ADDRESS: [
    {
      title: "Trụ sở chính",
      content: "18A Cộng Hòa, Phường 12, Quận Tân Bình, TPHCM",
    },
    { title: "Giờ làm việc", content: "7:00 - 20:00" },
    { title: "Email", content: "nhattin@ntlogistics.vn" },
    { title: "Hotline", content: "1900 63 66 88" },
  ],
  STATUS: [
    { title: "Chưa thành công", amount: 25, status: "undone" },
    { title: "Chờ lấy hàng", amount: 245, status: "waiting" },
    { title: "Đã lấy hàng", amount: 3, status: "picked" },
    { title: "Đang vận chuyển", amount: 245, status: "delivering" },
    { title: "Đã giao hàng", amount: 68, status: "deliveried" },
    { title: "Đã trả COD", amount: 23, status: "pay" },
    { title: "Đã hủy", amount: 1, status: "cancel" },
  ],
  KIND: [
    { title: "Thực phẩm", value: "food", id: 1 },
    { title: "Quà tặng", value: "gift", id: 2 },
    { title: "Hoa", value: "flower", id: 3 },
    { title: "Điện tử", value: "electric", id: 4 },
    { title: "Bánh ngọt", value: "sweet", id: 5 },
    { title: "Khác", value: "other", id: 6 },
  ],
  BigCities: [
    { title: "TP.HCM", value: "HCM", label: "Hồ Chí Minh", id: 50 },
    { title: "Hà Nội", value: "HN", label: "Hà Nội", id: 29 },
    { title: "Đà Nẵng", value: "DN", label: "Đà Nẵng", id: 43 },
  ],
  Alpha: [
    { letter: "A", id: "A" },
    { letter: "B", id: "B" },
    { letter: "C", id: "C" },
    { letter: "Đ", id: "Đ" },
    { letter: "G", id: "G" },
    { letter: "H", id: "H" },
    { letter: "K", id: "H" },
    { letter: "L", id: "L" },
    { letter: "N", id: "N" },
    { letter: "P", id: "P" },
    { letter: "Q", id: "Q" },
    { letter: "S", id: "S" },
    { letter: "T", id: "T" },
    { letter: "V", id: "V" },
    { letter: "Y", id: "Y" },
  ],
  BILLONLINE: [
    { title: "Loại vận chuyển", value: "Xe máy" },
    { title: "Phí dịch vụ", value: "30.000vnđ" },
    { title: "Tiền thu hộ COD", value: "250.000vnđ" },
  ],
  STATUSFILER: [
    // { title: "Tất cả", value: 10, id: "all" },
    // { title: "Đã hủy", value: 6, id: "Cancel" },
    // { title: "Đã xác nhận", value: 2, id: "Confirmed" },
    // { title: "Đã giao hàng", value: 5, id: "Deliverd" },
    // { title: "Đang giao", value: 4, id: "Delivering" },
    // { title: "Vận đơn mới", value: 1, id: "Open" },
    // { title: "Đã nhận hàng", value: 3, id: "Received" },
    // { title: "Chờ phản hồi", value: 7, id: "Reopen" },
    ///
    { title: "Tất cả", value: 10, id: "all" },
    { title: "Vận đơn mới", value: 1, id: "Open" },
    { title: "Đã xác nhận", value: 2, id: "Confirmed" },
    { title: "Đã gửi hàng", value: 3, id: "Received" },
    { title: "Đang giao hàng", value: 4, id: "Delivering" },
    { title: "Đã giao hàng", value: 5, id: "Delivered" },
    { title: "Hủy", value: 6, id: "Cancel" },
    { title: "Chuyển hoàn", value: 7, id: "Stuck" },
  ],

  SERVICE_TYPE: {
    STANDARD: { title: "Giao hàng chuẩn", value: 2 },
    EXPRESS: { title: "Giao hàng nhanh", value: 1 },
    TIMER: { title: "Hẹn giờ", value: 3 },
  },
  WEIGHT_PACKAGE: {
    MAX_THIRD: { title: "0 - 3Kg", value: 2, fee: 20000 },
    MAX_FIVE: { title: "3 - 5Kg", value: 4, fee: 25000 },
    ABOVE_FIVE: { title: "Trên 5Kg", value: 6, fee: 30000 },
  },
  BRAND: {
    FOOD: { title: "Thực phẩm", value: 1 },
    GIFT: { title: "Quà tặng", value: 2 },
    FLOWER: { title: "Hoa", value: 3 },
    ELECTRIC: { title: "Điện tử", value: 4 },
    SWEETSTUFF: { title: "Bánh ngọt", value: 5 },
  },
  ERROR_NOTI_DEFAULT: {
    EMPTY: { notification: "Số điện thoại không được rỗng" },
    EMPYFIELD: { notification: "Hãy nhập" },
    VALIDATE_PHONE: { notification: "Số điện thoại không đúng định dạng" },
  },
  DATE_TIME: {
    HOURS: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    MINUTES: ["00", "15", "30", "45"],
  },
  NOTSHOWDATE: "0000-00-00 00:00:00",
  UPDATE_TIME_OUT: 200,
  DELETE_BILL_STATUS_POSIBILE: [1, 2],
  DATE_RANGE_FILTER: [
    { name: "7 Ngày trước", value: "7 days", description: 7 },
    { name: "14 Ngày trước", value: "14 days", description: 14 },
    { name: "30 Ngày trước", value: "30 days", description: 30 },
    { name: "Tháng này", value: "this month", description: "month" },
    { name: "Tháng trước", value: "last month", description: "month" },
    { name: "Tùy chọn", value: "option", description: "month" },
  ],
  BILLS_NTL_STATUS: [
    { id: 14, status: -999, status_name: "Tất cả" },
    { id: 1, status: 1, status_name: "Chưa thành công" },
    { id: 2, status: 2, status_name: "Chờ lấy hàng" },
    { id: 17, status: 17, status_name: "Không lấy được" },
    { id: 3, status: 3, status_name: "Đã lấy hàng" },
    // { id: 15, status: 15, status_name: "Đang vận chuyển" },
    { id: 4, status: 4, status_name: "Đã giao hàng" },
    { id: 5, status: 4, status_name: "Nợ COD", is_debt: true },
    { id: 6, status: 5, status_name: "Đã trả COD", is_debt: false },
    { id: 7, status: 7, status_name: "Không phát được" },
    { id: 8, status: 6, status_name: "Đã hủy" },
    { id: 9, status: 9, status_name: "Đang chuyển hoàn" },
    // { id: 16, status: 16, status_name: "Đang giao hàng hoàn" },
    { id: 10, status: 10, status_name: "Đã chuyển hoàn" },
    { id: 11, status: 11, status_name: "Sự cố" },
    { id: 12, status: 12, status_name: "Vận đơn caresoft" },
  ],
  dateFormat: "DD/MM/YY",
  COD: [
    {
      id: 1,
      do_code: "EP1125D",
      cod_amount: 25_000,
      cod_take: 30_000,
      cod_paid: 150_000,
      cod_debt: 250_000,
    },
    {
      id: 2,
      do_code: "EP1125D@",
      cod_amount: 25_000,
      cod_take: 30_000,
      cod_paid: 150_000,
      cod_debt: 250_000,
    },
    {
      id: 3,
      do_code: "EP1125D!",
      cod_amount: 25_000,
      cod_take: 30_000,
      cod_paid: 150_000,
      cod_debt: 250_000,
    },
    {
      id: 4,
      do_code: "EP1125DE",
      cod_amount: 25_000,
      cod_take: 30_000,
      cod_paid: 150_000,
      cod_debt: 250_000,
    },
  ],
};
