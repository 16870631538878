import * as type from "../../actions/actionType";
const initState = {};
const promotionReducer = (state = initState, action) => {
  switch (action.type) {
    case type.START_FETCH_PROMOTION:
      return {
        ...state,
        success: null,
        data: [],
        messages: null,
        loading: true,
      };
    case type.SUCCESS_FETCH_PROMOTION:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        paging: action.paging,
        data: action.data,
        loading: false,
      };
    case type.FAILED_FETCH_PROMOTION:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        data: action.data,
        paging: null,
        total: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default promotionReducer;
