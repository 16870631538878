export const geoReducer = (
  state = {
    isLoading: false,
  },
  action
) => {
  let { payload } = action;
  switch (action.type) {
    case "GOOGLE_OBJ":
      return {
        ...state,
        geo: Object.values(payload || {}).length ? payload : null,
        geoReady: Object.values(payload || {}).length ? true : false,
      };
    default:
      return state;
  }
};

export default geoReducer;
