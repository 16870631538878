import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import createRootReducer from './reducers';

export const history = createBrowserHistory()

export default function configureStore() {
  const composeEnhancer = compose;
  const store = createStore(
      createRootReducer(history),
      composeEnhancer(applyMiddleware(thunk)),
  )

  return store
}

export const store = configureStore();
