import * as type from "../../actions/actionType";

const initState = {
  loading: true,
};

const customerReducer = (state = initState, action) => {
  switch (action.type) {
    case type.START_FETCH_CUSTOMERS:
      return {
        ...state,
        success: null,
        data: null,
        messages: null,
        loading: true,
      };
    case type.SUCCESS_FETCH_CUSTOMERS:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        data: action.data,
        loading: false,
        paging: action.paging,
      };
    case type.FAILED_FETCH_CUSTOMERS:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        data: action.data,
        loading: false,
      };
    case type.START_CREATE_CUSTOMER:
      return {
        ...state,
        success: null,
        dataCreate: null,
        messages: null,
        loading: true,
      };
    case type.SUCCESS_CREATE_CUSTOMER:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        dataCreate: action.data,
        loading: false,
        total: action.total,
      };
    case type.FAILED_CREATE_CUSTOMER:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        dataCreate: action.data,
        loading: false,
      };
    case type.START_FETCH_CUSTOMER_BY_ID:
      return {
        ...state,
        success: null,
        data: null,
        messages: null,
        loading: true,
      };
    case type.SUCCESS_FETCH_CUSTOMER_BY_ID:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        data: action.data,
        apps: action.apps,
        groups: action.groups,
        loading: false,
        total: action.total,
      };
    case type.FAILED_FETCH_CUSTOMER_BY_ID:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        apps: action.apps ?? [],
        groups: action.groups ?? [],
        data: action.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default customerReducer;
