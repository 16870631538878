import * as type from "../../actions/actionType";

const initState = {};

const formatBill = (action) => {
    let result = {
        success: action.success,
        dataChart: [],
        dataListBill: [],
        totalBill: 0,
        messages: action.messages,
    }
    if(action.data.length > 0){
        let series = action.data.map(item => {
            return {
                name: item.label,
                data: item.data,
                color: item.color,
            }
        })
        let categories = action.labels;

        let dataListBill = action.data.map(item => {
            return {
                id: item.bill_status_id,
                name: item.label,
                total: item.total,
                color: item.color,
            }
        })
        let totalBill = action.data.reduce((total,item) => {
            return total += item.total;
        },0)

        result={
            ...result,
            dataChart: {
                series,
                categories
            },
            dataListBill,
            totalBill
        }
    }
    
    return result;
}

const formatCodByMonth = (action) => {
    let result = {
        success: action.success,
        dataCodAmountByMonth: [],
        dataCodBillByMonth: [],
        messages: action.messages,
    }
    if(action.data.length > 0){
        let seriesBillCodData = action.data.map(item => {
            return item.total_bill_cod_in_month;
        })
        let seriesCodAmountData = action.data.map(item => {
            return item.total_cod_amount_in_month;
        })
        let categories = action.data.map(item => {
            return item.month;
        })

        let dataCodBillByMonth = {
            series: [
                {
                  name: 'Tổng đơn',
                  data: seriesBillCodData
                }
            ],
            categories,
            text: "Tổng đơn COD theo tháng"
        }

        let dataCodAmountByMonth = {
            series: [
                {
                  name: 'Tổng tiền',
                  data: seriesCodAmountData
                }
              ],
              categories,
              text: "Tổng tiền COD theo tháng"
        }

        result={
            ...result,
            dataCodAmountByMonth,
            dataCodBillByMonth,
        }
    }
    
    return result;
}

const formatDataByCod = action => {
    let result = {
        success: action.success,
        dataDonut: [],
        dataCodBill: [],
        dataCodAmount: [],
        messages: action.messages,
    }
    let series = [action.data?.total_income_bill,action.data?.total_paid_bill]

    let dataDonut = {
        series,
        total: action.data?.total_bill,
    }
    let dataCodBill = {
        totalBill: action.data?.total_bill,
        paidBill: action.data?.total_paid_bill,
        incomeBill: action.data?.total_income_bill,
    }
    let dataCodAmount = {
        totalAmount: action.data?.total_amount,
        paidAmount: action.data?.total_paid_amount,
        incomeAmount: action.data?.total_income_amount
    }

    result={
        ...result,
        dataDonut,
        dataCodBill,
        dataCodAmount
    }
    

    return result;
}

const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case type.BILL_STATISTIC:
        return {
            ...state,
            ...formatBill(action),
        };
    case type.BILL_STATISTIC_BY_MONTH:
        return {
            ...state,
            ...formatCodByMonth(action),
        };
    case type.BILL_STATISTIC_BY_COD:
        return {
            ...state,
            ...formatDataByCod(action),
        };
    case type.COD_STATISTIC:
        return {
            ...state,
            success: action.success,
            codIncome: action.success ? action.data.cod_income_sum : 0,
            messages: action.messages,
        };
    case type.MAINFEE_STATISTIC:
        return {
            ...state,
            success: action.success,
            mainFee: action.success ? action.data.total_main_fee : 0,
            messages: action.messages,
        };
    default:
      return state;
  }
};

export default dashboardReducer;
