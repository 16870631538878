import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Button,
  Input,
  Row,
  Checkbox,
  Col,
  Rate,
  Select,
} from "antd";
import * as api from "../../server/api";
import { notifySucess } from "../components/Notification";
import "./style.scss";
import iconSet from "assets/icomoon/selection.json";
import IcomoonReact from "icomoon-react";
import survey from "./../../assets/img/survey.svg";

import {
  CHECK_BOX_SURVEY_COL_1,
  CHECK_BOX_SURVEY_COL_2,
  RATE_DESC,
  OBJ_EXTRA,
  NTL,
  BackEnd_NTL,
  COL_DESTRUCTIVE_1,
  COL_DESTRUCTIVE_2,
  POINT_AVERAGE,
  SUCCESS_NOTI_FEEDBACK,
  TITLE_FEEDBACK_FORM,
  FEEDBACK_SERVICE_TITLE,
} from "constants/index";
// import helpers from "actions/lib/helpers";

const { TextArea } = Input;
const { Item } = Form;

const FeedBack = () => {
  const [form] = Form.useForm();
  // const { width } = useWindowDimensions();

  const handleExtraData = (arr) => {
    return arr.reduce((acc, i) => {
      const obj = {};
      obj.name = OBJ_EXTRA[i];
      obj.value = i;
      acc = [...acc, obj];
      return acc;
    }, []);
  };
  const API_PROVINCE = "v1/province";

  const [province, setProvince] = useState([]);

  const onFetchProvince = () => {
    api.index(API_PROVINCE, null, (res) => {
      if (!res.success) return console.log(res);
      setProvince(res.data);
    });
  };

  useEffect(() => {
    onFetchProvince();
  }, []);

  const onFinish = (values) => {
    const extra = form.getFieldValue("extra_data");
    const extra_data = handleExtraData(extra);
    console.log(form.getFieldsValue());
    let params = {
      company: NTL,
      customer_name: "Khách hàng",
      email: "ntlfeedback@gmail.com",
      phone: "1900638080",
      point: values.rate,
      source: BackEnd_NTL,
      extra_data: extra_data,
      note: values.note,
      ref_code: values.ref_code,
      province_id: values.province_id,
    };
    api.create("v1/customer-feedback", params, (res) => {
      notifySucess(SUCCESS_NOTI_FEEDBACK);
      form.resetFields();
    });
  };
  const renderDom = (i) => {
    return (
      <Col xxl={{ span: 24 }} xl={{ span: 24 }}>
        <Checkbox className="feed_back_checkbox" id={i.id} value={i.value}>
          {i.name}
        </Checkbox>
      </Col>
    );
  };
  const COL = {
    1: CHECK_BOX_SURVEY_COL_1,
    2: CHECK_BOX_SURVEY_COL_2,
  };
  const COL_DESTRUCTIVE = {
    1: COL_DESTRUCTIVE_1,
    2: COL_DESTRUCTIVE_2,
  };
  const renderColumns = (position) => {
    const rate = form.getFieldValue("rate");
    const col = rate <= POINT_AVERAGE ? COL_DESTRUCTIVE : COL;
    return (
      <Col xxl={{ span: 12 }} xl={{ span: 12 }} key={1}>
        <Row gutter={[6, 6]} className="feed_back__row">
          {col[position].map(renderDom)}
        </Row>
      </Col>
    );
  };
  const COLUMNS = [1, 2];
  const FORM_CONFIG = {
    name: "control-ref",
    layout: "vertical",
    className: "form-create-sender-address",
    initialValues: { rate: 5 },
  };
  const onValuesChange = (v) => {
    const key = Object.keys(v)[0];
    if (key !== "rate") return;
    form.resetFields(["extra_data"]);
  };

  return (
    <div
      style={{
        background:
          "url(/static/media/login_background.11023b82.png) no-repeat",
        padding: "2% 25%",
        height: "100vh",
      }}
    >
      <Form
        onValuesChange={onValuesChange}
        form={form}
        onFinish={onFinish}
        {...FORM_CONFIG}
      >
        <img
          src={survey}
          alt="Survey"
          style={{ width: "100%", marginBottom: "5px" }}
        />
        <Card style={{ borderRadius: "5px", marginBottom: "5px" }}>
          <Row gutter={7}>
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              style={{ textAlign: "center" }}
            >
              <h1>{FEEDBACK_SERVICE_TITLE}</h1>
            </Col>
            <Col xxl={{ span: 24 }} xl={{ span: 24 }}>
              <Form.Item
                name="province_id"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn tỉnh thành!",
                  },
                ]}
              >
                <Select
                  id="address_province"
                  autoComplete="none"
                  size="large"
                  suffixIcon={
                    <IcomoonReact
                      iconSet={iconSet}
                      size={20}
                      style={{ width: 12, height: 12, marginTop: -2 }}
                      icon="BONTL0024"
                    />
                  }
                  placeholder="Chọn Tỉnh/Thành"
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  allowClear
                >
                  {(province || []).map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item?.province_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* <Col span={6}>
              <FormText
                name="name"
                label="Bạn tên"
                placeholder="Nhập họ tên"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập họ tên ",
                  },
                ]}
              />
            </Col>
            <Col span={6}>
              <FormText
                name="phone"
                label="Số điện thoại"
                placeholder="Nhập số điện thoại"
                rules={[
                  ({ getFieldValue }) => ({
                    validator() {
                      let phone = getFieldValue("phone");
                      if (!phone || helpers.validateMobile(phone)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Vui lòng nhập số điện thoại đúng định dạng"
                      );
                    },
                  }),
                  {
                    required: true,
                    message: "Vui lòng nhập số điện thoại",
                  },
                ]}
              />
            </Col>
            <Col span={6}>
              <FormText
                name="email"
                label="Email"
                placeholder="Nhập email"
                type="email"
                rules={[
                  ({ getFieldValue }) => ({
                    validator() {
                      let email = getFieldValue("email");
                      if (!email || helpers.validateEmail(email)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Vui lòng nhập email đúng định dạng"
                      );
                    },
                  }),
                  {
                    required: true,
                    message: "Vui lòng nhập email",
                  },
                ]}
              />
            </Col>
            <Col span={6}>
              <FormText
                name="ref_code"
                label="Mã vận đơn"
                placeholder="Nhập mã vận đơn"
              />
            </Col> */}
          </Row>
        </Card>
        <Card style={{ borderRadius: "5px" }}>
          <Row gutter={24} style={{ textAlign: "center" }}>
            <Col
              xxl={{ span: 24 }}
              xl={{ span: 24 }}
              style={{ margin: "10px" }}
            >
              <Item name="rate">
                <Rate
                  allowClear={false}
                  tooltips={RATE_DESC}
                  style={{
                    fontSize: 36,
                  }}
                />
              </Item>
            </Col>
            <Col xxl={{ span: 24 }} xl={{ span: 24 }}>
              <h2>{TITLE_FEEDBACK_FORM}</h2>
            </Col>
            <div className="d_center">
              <Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.rate !== currentValues.rate
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("rate") && (
                    <Item
                      name="extra_data"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn đánh giá!",
                        },
                      ]}
                    >
                      <Checkbox.Group>
                        <div className="check-box-container">
                          {COLUMNS.map(renderColumns)}
                        </div>
                      </Checkbox.Group>
                    </Item>
                  )
                }
              </Item>
            </div>
            <Col xxl={{ span: 24 }} xl={{ span: 24 }}>
              <Item name="note">
                <TextArea
                  id="cs_note"
                  placeholder="Ý kiến khác...."
                  allowClear
                  size="large"
                />
              </Item>
            </Col>
            <Col xxl={{ span: 24 }} xl={{ span: 24 }}>
              <Item name="send">
                <Button
                  id="cs_submit"
                  htmlType="submit"
                  type="primary"
                  size="large"
                >
                  Gửi phản hồi
                </Button>
              </Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default FeedBack;
