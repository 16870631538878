import React, { Component } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { history } from "./history";
import qs from "query-string";
import "antd/dist/antd.css";
// import { connect } from 'react-redux';
// import { push } from 'connected-react-router';
import Constants from "./variables/Constants/index";
import "./scss/style.scss";
import * as api from "../src/server/api";
import FeedBack from "views/feeback/FeedBack";
// import Config from "./config.js";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/auth/Login"));
const Register = React.lazy(() => import("./views/auth/Register"));
const ConnectHaravan = React.lazy(() => import("./views/auth/ConnectHaravan"));
const ForgotPassword = React.lazy(() => import("./views/auth/ForgotPassword"));
const Page404 = React.lazy(() => import("./views/error/page404"));
const Page500 = React.lazy(() => import("./views/error/page505"));
const VerifyAccount = React.lazy(() => import("./views/auth/VerifyAccount"));
const CareSoft = React.lazy(() => import("./views/careshop/BillCareSoft"));
const PageThank = React.lazy(() => import("./views/feeback/PageThank"));

const ROUTE_NO_AUTH = Constants.ROUTE_NO_AUTH;

class App extends Component {
  componentDidMount() {
    // console.log("His",history.location);
    const query = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    // const environment = Config.REACT_APP_STAGE;
    // const baseUrl = Config.BASE_URL;
    console.log("QPrams", query);
    console.log("environment", window._env_.REACT_APP_STAGE);
    // console.log("environment", environment);
    // console.log("baseUrl", baseUrl);
    let _token = localStorage.getItem("token");
    let _path = history.location.pathname;
    if (!_token) {
      // if (_path !== "/verify-account") {
      //   history.push("/login");
      // }

      if (ROUTE_NO_AUTH.includes(_path)) {
        history.push({
          pathname: _path,
          search: history.location.search,
        });
      } else {
        history.push("/login");
      }
    }
  }

  componentWillMount() {
    let _path = history.location.pathname;

    if (!ROUTE_NO_AUTH.includes(_path)) {
      this.checkTokenIsExpires();
    }
  }

  checkTokenIsExpires = () => {
    const API_EXPIRES = "v1/check-sessionToken";
    let _token = localStorage.getItem("token");

    api.index(API_EXPIRES, { token: _token }, (res) => {
      if (!res.success) {
        localStorage.clear();
        // return history.push("/login");
        let _path = history.location.pathname;

        if (ROUTE_NO_AUTH.includes(_path)) {
          history.push({
            pathname: _path,
            search: history.location.search,
          });
        } else {
          history.push("/login");
        }
      }
    });
  };

  render() {
    //console.log("History",history);
    return (
      // <BrowserRouter>
      <Router history={history}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              // path="/create-bill-caresoft"
              path="/crm-cs"
              name="Tạo đơn caresoft"
              render={(props) => <CareSoft {...props} />}
            />
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/register-haravan"
              name="Register Haravan"
              render={(props) => <ConnectHaravan {...props} />}
            />
            <Route
              exact
              path="/feed-back"
              name="Feedback"
              render={(props) => <FeedBack {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/verify-account"
              name="Kích hoạt tài khoản"
              render={(props) => <VerifyAccount {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/thank-survey"
              name="Cám ơn đánh đã đánh giá"
              render={(props) => <PageThank {...props} />}
            />

            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </Router>
      // </BrowserRouter>
    );
  }
}

export default App;
