import * as type from "../../actions/actionType";

const initState = {
  loading: true,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case type.START_FETCH_USER:
      return {
        ...state,
        success: false,
        userCurrent: [],
        messages: null,
      };
    case type.SUCCESS_FETCH_USER:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        userCurrent: action.data,
      };
    case type.FAILED_FETCH_USER:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        userCurrent: action.data,
      };
    default:
      return state;
  }
};

export default userReducer;
