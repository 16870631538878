import * as type from '../actions/actionType';

const ordersReducer = (state = {}, action) => {

  switch (action.type) {
    case type.ORDER_LOADED:
      return {
        ...state,
        inProgress: false,
        response: action.data
      }
    default:
      return state;
  }
};

export default ordersReducer;
