import * as type from "../../actions/actionType";
const initState = {};
const billReducer = (state = initState, action) => {
  switch (action.type) {
    case type.START_FETCH_BILL:
      return {
        ...state,
        success: null,
        bills: null,
        messages: null,
        loading: true,
        paging: null,
      };
    case type.START_GET_CARGO_CONFIG:
      return {
        ...state,
        success: null,
        configBill: null,
        messages: null,
      };
    case type.START_GET_SERVICE_CONFIG:
      return {
        ...state,
        success: null,
        services: [],
        messages: null,
      };
    case type.SUCCESS_FETCH_BILL:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        bills: action.data,
        paging: action.paging,
        loading: false,
      };
    case type.SUCCESS_GET_CARGO_CONFIG:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        configBill: action.data,
        loading: false,
      };
    case type.SUCCESS_GET_SERVICE_CONFIG:
        return {
          ...state,
          success: action.success,
          messages: action.messages,
          services: action.data,
          loading: false,
        };
    case type.FAILED_FETCH_BILL:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        bills: action.data,
        total: null,
        loading: false,
        paging: null,
      };
    case type.FAIL_GET_CARGO_CONFIG:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        configBill: action.data,
      };
    case type.FAIL_GET_SERVICE_CONFIG:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        services: action.data || [],
      };
      case type.RESET_AUTH:
        return {};
    default:
      return state;
  }
};

export default billReducer;
