export const ORDER_LOADED = "ORDER_LOADED";
export const ORDER_LOADED_SUCCESS = "ORDER_LOADED_SUCCESS";
export const STATES_LOADED = "STATES_LOADED";

export const START_FETCH_CUSTOMERS = "START_FETCH_CUSTOMERS";
export const SUCCESS_FETCH_CUSTOMERS = "SUCCESS_FETCH_CUSTOMERS";
export const FAILED_FETCH_CUSTOMERS = "FAILED_FETCH_CUSTOMERS";

export const START_FETCH_CUSTOMER_BY_ID = "START_FETCH_CUSTOMER_BY_ID";
export const SUCCESS_FETCH_CUSTOMER_BY_ID = "SUCCESS_FETCH_CUSTOMER_BY_ID";
export const FAILED_FETCH_CUSTOMER_BY_ID = "FAILED_FETCH_CUSTOMER_BY_ID";

export const START_CREATE_CUSTOMER = "START_CREATE_CUSTOMER";
export const SUCCESS_CREATE_CUSTOMER = "SUCCESS_CREATE_CUSTOMER";
export const FAILED_CREATE_CUSTOMER = "FAILED_CREATE_CUSTOMER";

export const START_UPDATE_CUSTOMER = "START_UPDATE_CUSTOMER";
export const SUCCESS_UPDATE_CUSTOMER = "SUCCESS_UPDATE_CUSTOMER";
export const FAILED_UPDATE_CUSTOMER = "FAILED_UPDATE_CUSTOMER";

export const START_LOGIN = "START_LOGIN";
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const FAILED_LOGIN = "FAILED_LOGIN";

export const START_RESET_PASSWORD = "START_RESET_PASSWORD";
export const SUCCESS_RESET_PASSWORD = "SUCCESS_RESET_PASSWORD";
export const FAILED_RESET_PASSWORD = "FAILED_RESET_PASSWORD";

export const START_FETCH_OTP_RESET_PASSWORD = "START_FETCH_OTP_RESET_PASSWORD";
export const SUCCESS_FETCH_OTP_RESET_PASSWORD =
  "SUCCESS_FETCH_OTP_RESET_PASSWORD";
export const FAILED_FETCH_OTP_RESET_PASSWORD =
  "FAILED_FETCH_OTP_RESET_PASSWORD";

export const START_CONFIRM_OTP = "START_CONFIRM_OTP";
export const SUCCESS_CONFIRM_OTP = "SUCCESS_CONFIRM_OTP";
export const FAILED_CONFIRM_OTP = "FAILED_CONFIRM_OTP";

export const START_UPDATE_PARTNER = "START_UPDATE_PARTNER";
export const SUCCESS_UPDATE_PARTNER = "SUCCESS_UPDATE_PARTNER";
export const FAILED_UPDATE_PARTNER = "FAILED_UPDATE_PARTNER";

export const START_FETCH_OTP = "START_FETCH_OTP";
export const SUCCESS_FETCH_OTP = "SUCCESS_FETCH_OTP";
export const FAILED_FETCH_OTP = "FAILED_FETCH_OTP";

export const START_REGISTER_EMAIL = "START_REGISTER_EMAIL";
export const SUCCESS_REGISTER_EMAIL = "SUCCESS_REGISTER_EMAIL";
export const FAILED_REGISTER_EMAIL = "FAILED_REGISTER_EMAIL";

export const START_GET_CURRENT_USER = "START_GET_CURRENT_USER";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";

export const BILL_STATISTIC = "BILL_STATISTIC";
export const BILL_STATISTIC_BY_MONTH = "BILL_STATISTIC_BY_MONTH";
export const BILL_STATISTIC_BY_COD = "BILL_STATISTIC_BY_COD";
export const COD_STATISTIC = "COD_STATISTIC";
export const MAINFEE_STATISTIC = "MAINFEE_STATISTIC";

export const START_FETCH_COD = "START_FETCH_COD";
export const SUCCESS_FETCH_COD = "SUCCESS_FETCH_COD";
export const FAILED_FETCH_COD = "FAILED_FETCH_COD";

export const START_FETCH_BANK = "START_FETCH_BANK";
export const SUCCESS_FETCH_BANK = "SUCCESS_FETCH_BANK";
export const FAILED_FETCH_BANK = "FAILED_FETCH_BANK";

export const START_FETCH_BANK_PARTNER = "START_FETCH_BANK_PARTNER";
export const SUCCESS_FETCH_BANK_PARTNER = "SUCCESS_FETCH_BANK_PARTNER";
export const FAILED_FETCH_BANK_PARTNER = "FAILED_FETCH_BANK_PARTNER";

export const START_FETCH_POSTAGE = "START_FETCH_POSTAGE";
export const SUCCESS_FETCH_POSTAGE = "SUCCESS_FETCH_POSTAGE";
export const FAILED_FETCH_POSTAGE = "FAILED_FETCH_POSTAGE";

export const START_FETCH_BILL = "START_FETCH_BILL";
export const SUCCESS_FETCH_BILL = "SUCCESS_FETCH_BILL";
export const FAILED_FETCH_BILL = "FAILED_FETCH_BILL";

export const START_GET_CARGO_CONFIG = 'START_GET_CARGO_CONFIG';
export const FAIL_GET_CARGO_CONFIG = 'FAIL_GET_CARGO_CONFIG';
export const SUCCESS_GET_CARGO_CONFIG = 'SUCCESS_GET_CARGO_CONFIG';

export const START_FETCH_USER = "START_FETCH_USER";
export const SUCCESS_FETCH_USER = "SUCCESS_FETCH_USER";
export const FAILED_FETCH_USER = "FAILED_FETCH_USER";

export const START_FETCH_PROMOTION = "START_FETCH_PROMOTION";
export const SUCCESS_FETCH_PROMOTION = "SUCCESS_FETCH_PROMOTION";
export const FAILED_FETCH_PROMOTION = "FAILED_FETCH_PROMOTION";

export const START_GET_SERVICE_CONFIG = 'START_GET_SERVICE_CONFIG';
export const FAIL_GET_SERVICE_CONFIG = 'FAIL_GET_SERVICE_CONFIG';
export const SUCCESS_GET_SERVICE_CONFIG = 'SUCCESS_GET_SERVICE_CONFIG';
export const RESET_AUTH = 'RESET_AUTH';
