import * as type from '../actions/actionType';

const statesReducer = (state = {}, action) => {

  switch (action.type) {
    case type.STATES_LOADED:
      return {
        ...state,
        loading: false,
        data: action.data
      }
    default:
      return state
  }
};

export default statesReducer;
