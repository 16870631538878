import axios from "axios";
import { history } from "../history";
import { Modal } from "antd";
import { API_URL, CDN_URL, CDN_TOKEN, SANDBOX_URL } from "./config";
import { store } from "../store";
import md5 from "md5";
import moment from "moment";

const endpoint = API_URL;
const sandbox_url = SANDBOX_URL;

axios.defaults.timeout = 300 * 1000;

axios.interceptors.request.use((req) => {
  console.log("vào");
  store.dispatch({
    type: "IS_START",
  });
  return req;
});

const isDone = (_) => {
  return store.dispatch({
    type: "IS_DONE",
  });
};

/*-----------------------------GET ALL----------------------------------------*/
export function index(api_name, params, callback) {
  let _apiUrl = endpoint + api_name;
  let _headers = setHeaders();
  let _params = setParams(params);
  let _authorization = localStorage.getItem("token");
  let _uuid = localStorage.getItem("uuid");
  axios.defaults.headers.common["Authorization"] = _authorization;
  if (_uuid) {
    axios.defaults.headers.common["x-api-key"] = _uuid;
  }

  // axios.defaults.headers.common["app_id"] = APP_ID;

  axios
    .get(_apiUrl, _params, _headers)
    .then(function (response) {
      // a.push("/login");
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
      catchError(error);
    })
    .finally(isDone);
}

export async function index2(api_name, params, callback) {
  let _apiUrl = endpoint + api_name;
  let _headers = setHeaders();
  let _params = setParams(params);
  let _authorization = localStorage.getItem("token");
  let _uuid = localStorage.getItem("uuid");
  axios.defaults.headers.common["Authorization"] = _authorization;
  if (_uuid) {
    axios.defaults.headers.common["x-api-key"] = _uuid;
  }

  // axios.defaults.headers.common["app_id"] = APP_ID;

  return axios
    .get(_apiUrl, _params, _headers)
    .finally(isDone);
}

/*-----------------------------GET ONE----------------------------------------*/
export function show(api_name, id, params, callback) {
  let _apiUrl = endpoint + api_name + "/" + id;
  let _headers = setHeaders();
  let _params = setParams(params);
  let _authorization = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = _authorization;

  axios
    .get(_apiUrl, _params, _headers)
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      catchError(error);
    })
    .finally(isDone);
}

/*-----------------------------POST-------------------------------------------*/
export function create(api_name, params, callback) {
  let _apiUrl = endpoint + api_name;
  let _headers = setHeaders();
  let _uuid = localStorage.getItem("uuid");
  if (_uuid) {
    params.uuid = _uuid;
  }
  let _params = JSON.stringify(params);
  let _authorization = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = _authorization;
  axios.defaults.headers.common["x-api-key"] = _uuid;

  //axios
  // .post(_apiUrl, _params, _headers)
  axios({
    method: "post",
    url: _apiUrl,
    timeout: 1000 * 60 * 4,
    headers: _headers.headers,
    data: _params,
  })
    // .post(_apiUrl, _params, _headers)
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      catchError(error);
    })
    .finally(isDone);
}

export function signup(api_name, params, callback) {
  let _apiUrl = endpoint + api_name;
  if (params.phone) {
    params.uuid = md5(params.phone + "NTL@@001")
  }
  // let _headers = setHeaders();
  let _params = JSON.stringify(params);
  let _authorization = md5("NTL@!#" + moment().format("DDMMYYYY"))
  axios.defaults.headers.common["Authorization"] = _authorization;

  //axios
  // .post(_apiUrl, _params, _headers)
  axios({
    method: "post",
    url: _apiUrl,
    timeout: 1000 * 60 * 4,
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: _authorization,
    },
    data: _params,
  })
    // .post(_apiUrl, _params, _headers)
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      catchError(error);
    })
    .finally(isDone);
}

export function del(api_name, id, params, callback) {
  let _apiUrl = endpoint + api_name + "/" + id;
  let _headers = setHeaders();
  let _params = setParams(params);
  let _authorization = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = _authorization;

  axios
    .delete(_apiUrl, _params, _headers)
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      throw error;
    })
    .finally(isDone);
}

/*-----------------------------SIGIN------------------------------------------*/
export function sigin(api_name, params, callback) {
  let _apiUrl = endpoint + api_name;
  let _headers = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  let _params = JSON.stringify(params);

  axios
    .post(_apiUrl, _params, _headers)
    .then(function (response) {
      let data = response.data;
      if (data.success === false) {
        Modal.error({
          title: "Đăng nhập thất bại",
          content: data.message,
          onOk: () => {
            if (data.data.need_change_pass) {
              history.push({
                pathname: "/forgot-password",
                state: {
                  tab: "1",
                },
              });
            }
          }
        });
      } else {
        callback(data);
      }
    })
    .catch((error) => {
      catchError(error);
    })
    .finally(isDone);
}

/*-----------------------------PUT--------------------------------------------*/
export function update(api_name, id, params, callback) {
  let _apiUrl = endpoint + api_name + "/" + id;
  if (id === "") {
    _apiUrl = endpoint + api_name;
  }
  let _headers = setHeaders();
  let _params = JSON.stringify(params);
  let _authorization = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = _authorization;
  // axios.defaults.headers.common["app_id"] = APP_ID;

  axios
    .put(_apiUrl, _params, _headers)
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      catchError(error);
    })
    .finally(isDone);
}

/*-----------------------------UPLOAD IMAGE-----------------------------------*/
export function uploadImages(api_name, params, files, callback) {
  let _apiUrl = endpoint + api_name;
  let formData = new FormData();
  let _authorization = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = _authorization;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const val = params[key];
      formData.append(key, val);
    }
  }

  let i = 0;
  for (i = 0; i < files.length; i++) {
    let file = files[i];
    formData.append("images[]", file, file.name);
  }

  axios
    .post(_apiUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      throw error;
    })
    .finally(isDone);
}

/*-----------------------------UPLOAD FILE-----------------------------------*/
export function uploadFile(api_name, params, file, callback) {
  // let _apiUrl = endpoint + api_name;
  let _apiUrl = endpoint + api_name;

  let formData = new FormData();
  let _authorization = localStorage.getItem("token");

  axios.defaults.headers.common["Authorization"] = _authorization;
  if (params.type === 1) {
    formData.append("bill_file", file);
    formData.append("sender_province_id", params.province_id);
    formData.append("sender_district_id", params.district_id);
    formData.append("sender_ward_id", params.ward_id);
  }

  if (params.type === 2) {
    formData.append("bill_file", file);
    formData.append("receiver_province_id", params.province_id);
    formData.append("receiver_district_id", params.district_id);
    formData.append("receiver_ward_id", params.ward_id);
  }

  if (params.type === 3) {
    formData.append("bill_file", file);
  }

  axios
    .post(_apiUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      throw error;
    })
    .finally(isDone);
}

/*-----------------------------PART FILE-------------------------------------*/

export function exportExcel(api_name, params) {
  let _apiUrl = API_URL + api_name;
  let _authorization = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = _authorization;

  axios({
    url: _apiUrl,
    params: params,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      let newDate = new Date();
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      link.href = url;

      let nameFile = "Template-";
      let typeFile = "Sender-";
      if (params.bill) {
        nameFile = "Mau-bill-";
      }

      if (params.bill_type === "receiver") {
        typeFile = "Receiver-";
      }

      if (params.bill_type === "multi") {
        typeFile = "Multi-";
      }

      if (params.type === "address") {
        typeFile = "address-";
      }

      link.setAttribute(
        "download",
        nameFile + typeFile + year + month + date + ".xlsx"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(isDone);
}

export function exportExcelPostMethod(api_name, params, type = "file") {
  let _apiUrl = API_URL + api_name;
  let _authorization = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = _authorization;

  axios({
    url: _apiUrl,
    method: "POST",
    responseType: "blob",
    data: params,
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      let newDate = new Date();
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      link.href = url;

      link.setAttribute(
        "download",
        "Bill-Error-" + year + month + date + ".xlsx"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(isDone);
}

export function getExcelFile(api_name, params, callback) {
  let _apiUrl = API_URL + api_name;
  let _authorization = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = _authorization;

  axios({
    url: _apiUrl,
    params: params,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(isDone);
}

/*-----------------------------PART FILE-------------------------------------*/

// export function exportPDF(api_name, params, callback) {
//   let _apiUrl = endpoint + api_name;
//   let _headers = setHeaders();
//   let _params = JSON.stringify(params);
//   let _authorization = localStorage.getItem("token");
//   axios.defaults.headers.common["Authorization"] = _authorization;
//   // axios.defaults.headers.common["app_id"] = APP_ID;

//   axios({
//     url: _apiUrl, //your url
//     method: "POST",
//     params: { ...params },
//     responseType: "blob", // important
//   }).then((response) => {
//     const url = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement("a");
//     link.href = url;
//     link.setAttribute("download", "file.pdf"); //or any other extension
//     document.body.appendChild(link);
//     link.click();
//   });
// }

/*-----------------------------PDF-------------------------------------------*/
export function exportPDF(api_name, params, callback) {
  let _apiUrl = endpoint + api_name;
  let _params = JSON.stringify(params);
  let _authorization = localStorage.getItem("token");
  // axios.defaults.headers.common["Authorization"] = _authorization;

  axios
    .get(_apiUrl, _params, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        Authorization: _authorization,
      },
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      // link.setAttribute("download", "file.pdf"); //or any other extension
      link.setAttribute("target", "_blank"); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => console.log(error))
    .finally(isDone);
}

/*-----------------------------POST-------------------------------------------*/
export function exportPDF3(api_name, params, callback) {
  let _apiUrl = sandbox_url + api_name;
  axios({
    method: "post",
    url: _apiUrl,
    responseType: "blob",
    data: params,
  })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(isDone);
}

export function callToSandbox(api_name, params, callback) {
  let _apiUrl = sandbox_url + api_name;
  let _headers = setHeaders();
  let _uuid = localStorage.getItem("uuid");
  if (_uuid) {
    params.uuid = _uuid;
  }
  let _params = JSON.stringify(params);
  let _authorization = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = _authorization;
  axios.defaults.headers.common["x-api-key"] = _uuid;

  //axios
  // .post(_apiUrl, _params, _headers)
  axios({
    method: "post",
    url: _apiUrl,
    timeout: 1000 * 60 * 4,
    headers: _headers.headers,
    data: _params,
  })
    // .post(_apiUrl, _params, _headers)
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      catchError(error);
    })
    .finally(isDone);
}


export function exportPDF2(api_name, params, callback) {
  let _apiUrl = endpoint + api_name;
  let _authorization = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = _authorization;
  // axios.defaults.headers.common["app_id"] = APP_ID;

  axios({
    url: _apiUrl,
    params: params,
    method: "GET",
    responseType: "blob",
  })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(isDone);
}

/*-----------------------------SET HEADERS------------------------------------*/
export function setHeaders() {
  let _authorization = localStorage.getItem("token");
  let _axios_header = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: _authorization,
    },
  };
  return _axios_header;
}

/*-----------------------------SET PARAMS-------------------------------------*/
export function setParams(params) {
  let axios_params = {
    params: params,
  };
  return axios_params;
}

export function catchError(error) {
  console.log(error);

  if (error.response?.status === 404) {
    console.log("404");
    // history.push("#/404");
    Modal.error({
      title: "Thông Báo",
      content: error.response?.statusText,
      onOk: () => {
        history.push("#/404");
      },
    });
  } else if (error.response?.status === 500) {
    console.log("500");
    Modal.error({
      title: "Thông Báo",
      content: error.response.statusText,
      onOk: () => {
        window.location.reload();
      },
    });
    // history.push("#/500");
  } else if (error.response?.status === 408) {
    console.log("408");
    Modal.error({
      title: "Thông Báo",
      content: "Phiên đăng nhập đã hết hạn",
      onOk: () => {
        history.push("/login");
        window.location.reload(true);
      },
    });
  } else if (error.response?.status === 401) {
    console.log("401");
    Modal.error({
      title: "Thông Báo",
      content: "Bạn Không có quyền truy cập",
      onOk: () => {
        history.push("/");
        window.location.reload(true);
      },
    });
  } else if (error.response?.status === 400) {
    console.log("400");
    Modal.error({
      title: "Thông Báo",
      content: error.response.data.message,
      onOk: () => {
        history.push("/");
        window.location.reload(true);
      },
    });
  }
}

/*-----------------------------UPLOAD IMAGE/FILE CDN-------------------------------------*/
export function uploadImageCDN(api_name, params, callback) {
  let _apiUrl = CDN_URL + api_name;
  let _headers = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: CDN_TOKEN,
    },
  };
  let _params = JSON.stringify(params);

  axios
    .post(_apiUrl, _params, _headers)
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      catchError(error);
    });
}

export function uploadFileCDN(api_name, file, callback) {
  let _apiUrl = CDN_URL + api_name;
  let formData = new FormData();
  let _headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: CDN_TOKEN,
    },
  };
  formData.append("file", file);

  axios
    .post(_apiUrl, formData, _headers)
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      catchError(error);
    });
}

/*-----------------------------POST NO TOKEN-------------------------------------------*/
export function postNoToken(api_name, params, callback) {
  let _apiUrl = endpoint + api_name;
  let _headers = setHeaders();
  let _uuid = md5("Saila@0910|" + moment().format("YYYY-MM-DD"));
  axios.defaults.headers.common["x-api-key"] = _uuid;
  let _params = JSON.stringify(params);
  //axios
  // .post(_apiUrl, _params, _headers)
  axios({
    method: "post",
    url: _apiUrl,
    timeout: 1000 * 60 * 4,
    headers: _headers.headers,
    data: _params,
  })
    // .post(_apiUrl, _params, _headers)
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      catchError(error);
    })
    .finally(isDone);
}

/*-----------------------------GET ALL NO TOKEN----------------------------------------*/
export function getAll(api_name, params, callback) {
  let _apiUrl = endpoint + api_name;
  let _headers = setHeaders();
  let _params = setParams(params);

  let _uuid = md5("Saila@0910|" + moment().format("YYYY-MM-DD"));
  axios.defaults.headers.common["x-api-key"] = _uuid;

  axios
    .get(_apiUrl, _params, _headers)
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
      catchError(error);
    })
    .finally(isDone);
}

/*-----------------------------GET ONE NO TOKEN----------------------------------------*/
export function getOneNoToken(api_name, id, params, callback) {
  let _apiUrl = endpoint + api_name + "/" + id;
  let _headers = setHeaders();
  let _params = setParams(params);

  let _uuid = md5("Saila@0910|" + moment().format("YYYY-MM-DD"));
  axios.defaults.headers.common["x-api-key"] = _uuid;

  axios
    .get(_apiUrl, _params, _headers)
    .then(function (response) {
      callback(response.data);
    })
    .catch((error) => {
      catchError(error);
    })
    .finally(isDone);
}

/*-----------------------------GET ONE NO TOKEN----------------------------------------*/
export function getByTokenWeb(api_name, params, token) {
  let _apiUrl = api_name ;
  let _params = setParams(params);
  let _headers = {headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: token,
  }, params: {
    overrideAuth: token
  }}
  // let token = md5("NTL@!#" + moment().format("DDMMYYYY"));
  axios.defaults.headers.common["Authorization"] = token;
  console.log('axios.defaults.headers', axios.defaults.headers)
  return axios
    .get(_apiUrl, _headers)
    // .then(function (response) {
    //   callback(response.data);
    // })
    .catch((error) => {
      catchError(error);
    })
    .finally(isDone);
}

export function post(api_name, params, callback) {
  let _apiUrl = endpoint + api_name;
  let _headers = setHeaders();
  let _uuid = localStorage.getItem("uuid");
  if (_uuid) {
    params.uuid = _uuid;
  }
  let _params = JSON.stringify(params);
  let _authorization = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = _authorization;

  //axios
  // .post(_apiUrl, _params, _headers)
  return axios({
    method: "post",
    url: _apiUrl,
    timeout: 1000 * 60 * 4,
    headers: _headers.headers,
    data: _params,
  })
    .catch((error) => {
      catchError(error);
    })
    .finally(isDone);
}
