import * as type from "../../actions/actionType";
const initState = {};
const codReducer = (state = initState, action) => {
  switch (action.type) {
    case type.START_FETCH_COD:
      return {
        ...state,
        success: null,
        dataCod: null,
        messages: null,
        loading: true,
      };
    case type.SUCCESS_FETCH_COD:
      console.log("SUCCESS_FETCH_COD");
      console.log(action);
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        dataCod: action.data,
        loading: false,
      };
    case type.FAILED_FETCH_COD:
      return {
        ...state,
        success: action.success,
        messages: action.messages,
        dataCod: action.data,
        total: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default codReducer;
