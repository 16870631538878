export default {
  ROUTE_NO_AUTH: [
    "/login",
    "/register",
    "/forgot-password",
    "/verify-account",
    "/404",
    "/500",
    "/crm-cs",
    "/manager/create-orders",
    "/register-haravan",
    "/feed-back"
  ],

  LISTCODFEE: { 
    Received: { title: "Đã lấy hàng", value: 3 },
    Delivering: { title: "NTL nợ COD", value: -4, is_debt: "true" },
    Delivered: { title: "NTL đã trả", value: -4, is_debt: "false" },
  },

  ADDRESS: [
    {
      title: "Trụ sở chính",
      content: "18A Cộng Hòa, Phường 12, Quận Tân Bình, TPHCM",
    },
    { title: "Giờ làm việc", content: "7:00 - 20:00" },
    { title: "Email", content: "nhattin@ntlogistics.vn" },
    { title: "Hotline", content: "1900 63 66 88" },
  ],
  STATUS: [
    { title: "Chưa thành công", amount: 25, status: "undone" },
    { title: "Chờ lấy hàng", amount: 245, status: "waiting" },
    { title: "Đã lấy hàng", amount: 3, status: "picked" },
    { title: "Đang vận chuyển", amount: 3, status: "delivering" },
    { title: "Đã giao hàng", amount: 68, status: "deliveried" },
    { title: "Đã trả COD", amount: 23, status: "pay" },
    { title: "Đã hủy", amount: 1, status: "cancel" },
  ],
  KIND: [
    { title: "Thực phẩm", value: "food", id: 1 },
    { title: "Quà tặng", value: "gift", id: 2 },
    { title: "Hoa", value: "flower", id: 3 },
    { title: "Điện tử", value: "electric", id: 4 },
    { title: "Bánh ngọt", value: "sweet", id: 5 },
    { title: "Khác", value: "other", id: 6 },
  ],
  BigCities: [
    {
      title: "TP.HCM",
      value: "HCM",
      label: "Hồ Chí Minh",
      id: 50,
      tabIndex: 0,
    },
    { title: "Hà Nội", value: "HN", label: "Hà Nội", id: 29, tabIndex: 1 },
    { title: "Đà Nẵng", value: "DN", label: "Đà Nẵng", id: 43, tabIndex: 2 },
  ],
  Alpha: [
    { letter: "A", id: "A" },
    { letter: "B", id: "B" },
    { letter: "C", id: "C" },
    { letter: "Đ", id: "Đ" },
    { letter: "G", id: "G" },
    { letter: "H", id: "H" },
    { letter: "K", id: "H" },
    { letter: "L", id: "L" },
    { letter: "N", id: "N" },
    { letter: "P", id: "P" },
    { letter: "Q", id: "Q" },
    { letter: "S", id: "S" },
    { letter: "T", id: "T" },
    { letter: "V", id: "V" },
    { letter: "Y", id: "Y" },
  ],
  BILLONLINE: [
    { title: "Loại vận chuyển", value: "Xe máy" },
    { title: "Phí dịch vụ", value: "30.000vnđ" },
    { title: "Tiền thu hộ COD", value: "250.000vnđ" },
  ],
  STATUSFILER: [
    { title: "Tất cả", value: 98, id: "all" },
    { title: "Vận đơn mới", value: 1, id: "Open" },
    { title: "Đã xác nhận", value: 2, id: "Confirmed" },
    { title: "Đã lấy hàng", value: 3, id: "Received" },
    { title: "Đang vận chuyển", value: 10, id: "Transfering" },
    { title: "Đang giao", value: 4, id: "Delivering" },
    { title: "Giao thành công", value: 5, id: "Delivered" },

    { title: "Đang chuyển hoàn", value: 8, id: "Returning" },
    { title: "Đã chuyển hoàn", value: 7, id: "Returned" },
    { title: "Lỗi lấy hàng", value: 12, id: "ErrorReceived" },
    { title: "Lỗi giao hàng", value: 13, id: "ErrorDelivery" },

    { title: "Không giao được", value: 9, id: "NotDelivery" },
    { title: "Không lấy được", value: 11, id: "NoReceived" },
    { title: "Hủy", value: 6, id: "Cancel" },
    // { title: "Đơn sự cố", value: 99, id: "Issue" },
  ],

  BILLSTATUS: {
    Issue: { value: 99 },
  },

  // LISTCODFEE: {
  //   Received: { title: "Đã lấy hàng", value: 3 },
  //   Delivering: { title: "Đang giao hàng", value: 4 },
  //   Delivered: { title: "Đã giao hàng", value: 5 },
  //   Stuck: { title: "Chuyển hoàn", value: 7 },
  // },

  SERVICE_TYPE: {
    STANDARD: { title: "Giao chuẩn", value: 2, code: "CH" },
    EXPRESS: { title: "Giao nhanh", value: 1, code: "NH" },
    TIMER: { title: "Hẹn giờ", value: 3, code: "HG" },
  },
  WEIGHT_PACKAGE: {
    MAX_THIRD: { title: "0 - 2Kg", value: 1, fee: 20000 },
    MAX_FIVE: { title: "2 - 5Kg", value: 3.5, fee: 25000 },
    ABOVE_FIVE: { title: "Trên 5Kg", value: 6, fee: 30000 },
  },
  BRAND: {
    FOOD: { title: "Thực phẩm", value: 1 },
    GIFT: { title: "Quà tặng", value: 2 },
    FLOWER: { title: "Hoa", value: 3 },
    ELECTRIC: { title: "Điện tử", value: 4 },
    SWEETSTUFF: { title: "Bánh ngọt", value: 5 },
  },
  ERROR_NOTI_DEFAULT: {
    EMPTY: { notification: "Số điện thoại không được rỗng" },
    EMPYFIELD: { notification: "Hãy nhập" },
    VALIDATE_PHONE: { notification: "Số điện thoại không đúng định dạng" },
    EMPTY_ADDRESS: { notification: "Vui lòng nhập địa chỉ" },
    EMPTY_FIELD: "Vui lòng không bỏ trống trường này",
  },
  DATE_TIME: {
    HOURS: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    MINUTES: ["00", "15", "30", "45"],
  },
  NOTSHOWDATE: "0000-00-00 00:00:00",
  UPDATE_TIME_OUT: 250,
  DELETE_BILL_STATUS_POSIBILE: [1, 2],
  DATE_RANGE_FILTER: [
    { name: "7 Ngày trước", value: "7 days", description: 7 },
    { name: "14 Ngày trước", value: "14 days", description: 14 },
    { name: "30 Ngày trước", value: "30 days", description: 30 },
    { name: "Tháng này", value: "this month", description: "month" },
    { name: "Tháng trước", value: "last month", description: "month" },
    { name: "Tùy chọn", value: "option", description: "month" },
  ],
  NTX_INFORMATION: {
    HOTLINE: {
      content: "1900 63 8080",
    },
    EMAIL: {
      content: "nhattin@ntx.com.vn",
    },
  },
  MOMENT_FORMAT_VALUE: {
    DATE_MONTH_YEART: "DD/MM/YYYY",
  },
  OVER_TIME_NTX: 19,
  BOOKS_ADDRESS_TYPE: {
    SEND: 1,
    RECEIVER: 2,
    ACTION: "A",
    DELETE: "D",
  },
  PACKAGE_NOTE: [
    { name: "Không cho xem hàng", value: 1, label: "show" },
    { name: "Cho xem hàng", value: 2, label: "noshow" },
  ],
  NEWS: [
    { label: "Tất cả", value: "all", id: 1 },
    { label: "Đã xuất bản", value: "A", id: 2 },
    { label: "Đã ẩn", value: "D", id: 3 },
  ],
  LIMIT_BOOKS_ADDRESS: 9,
  HOTKEYS: ["c", "n", "t", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  OPTIONS_LOG: [
    { label: "Đã upload", value: "U", id: 1 },
    { label: "Đã tạo", value: "C", id: 2 },
  ],
  PAYMENT: { NGTTN: 10, NNTTN: 20 },
  UTM_SOURCE: "BackEnd",
  QUEUE_NAME: "CreateBillNTXAuth",
  TRANSPORT: [
    {
      id: 1,
      label: "Lấy hàng bằng xe máy",
      value: 20556,
    },
    { id: 2, label: "Lấy hàng bằng oto", value: 20558 },
  ],
  AMOUNT: { min: 1_000, max: 20_000_000 },
  CREATEBILL: [
    "authorization",
    "cargo_content",
    "cargo_content_field",
    "cargo_content_id",
    "cargo_value",
    "cod_amount",
    "cod_fee",
    "controlAmt",
    "counting_fee",
    "coupon_code",
    "delivery_fee",
    "dimension_weight",
    "discount",
    "expected_at",
    "height",
    "insurr_fee",
    "is_deduct_cod",
    "length",
    "lifting_fee",
    "main_fee",
    "note",
    "other_fee",
    "package_no",
    "packing_fee",
    "payment_method",
    "payment_method_id",
    "queue_name",
    "r_address",
    "r_district_id",
    "r_name",
    "r_phone",
    "r_province_id",
    "r_ward_id",
    "remote_fee",
    "s_address",
    "s_district_id",
    "s_name",
    "s_phone",
    "s_province_id",
    "s_ward_id",
    "sender_pay_cod",
    "service_id",
    "transfer_cod",
    "transport_by_id",
    "user_id",
    "utm_source",
    "weight",
    "width",
    "ref_code",
  ],
  GOOGLE_MAP_KEY: "AIzaSyD7olF0Sb5ivQrlvglpJxJgNnEnDcpk22M",
  CALCULATEDREF: [
    "s_district_id",
    "s_province_id",
    "r_province_id",
    "r_district_id",
    "weight",
    "length",
    "height",
    "width",
    "cargo_value",
    "payment_method_id",
    "package_no",
    "cod_amount",
  ],
  SHOPMANAGER: [
    { name: "Cửa hàng", value: "shop" },
    { name: "Phân quyền", value: "authorize" },
    { name: "Nhân viên", value: "employee" },
  ],
  SHOPMANAGERCHILD: [
    { name: "Cửa hàng", value: "shop" },
    { name: "Phân quyền", value: "authorize" },
  ],
  KEY_SHOP: "shop",
  PAGE_DEFAULT: 1,
  PAGE_SIZE_DEFAULT: 10,
  SHOP_TAG: {
    A: { color: "#01B2E8", name: "Đang hoạt động" },
    D: { color: "#959595", name: "Ngưng hoạt động" },
  },
  AUTHORIZE: "authorize",
  EMPLOYEE: "employee",
  ADDRESS_STORE: [
    "address",
    "province_id",
    "province_name",
    "district_id",
    "district_name",
    "ward_id",
    "ward_name",
  ],
  PERMISSION_FIELDS: ["phone", "email", "partner_name"],
  EMPLOYEE_FIELDS_SUBMIT: [
    "phone",
    "partner_name",
    "email",
    "permission_id",
    "permission_name",
    "shop_id",
    "shop_name",
    "status",
    "exist",
    "province_id",
    "province_name",
    "district_id",
    "district_name",
    "ward_id",
    "ward_name",
    "address",
    "partner_address_id",
    "permission_value",
    "country_id",
  ],
  EMPLOYEE_FIELDS: [
    "shop_name",
    "permission_name",
    "province_id",
    "province_name",
    "district_id",
    "district_name",
    "ward_id",
    "ward_name",
    "address",
    "partner_address_id",
    "permission_value",
  ],
  EMPLOYEE_FIELDS_EXIST: [
    "exist_partner_name",
    "exist_partner_phone",
    "exist_partner_email",
    "exist_shop_name",
    "exist_permission_name",
    "exist_province_id",
    "exist_district_id",
    "exist_ward_id",
    "exist_address",
    "exist_province_name",
    "exist_district_name",
    "exist_ward_name",
    "exist_partner_address_id",
    "exist_permission_value",
  ],
  PASSWORD_LENGTH: 8,
  PASSWORD: "password",
  PHONE: "phone",
  EMAIL: "email",
  NUMBER: "number",
  MESSAGE_VALIDATE: {
    phone: {
      type: "number",
      placeholder: "Nhập số điện thoại",
      message: "Vui lòng nhập số điện thoại!",
      errorMess: "Số điện thoại chưa đúng định dạng!",
    },
    email: {
      placeholder: "Nhập email",
      message: "Vui lòng nhập email!",
      errorMess: "Email chưa đúng định dạng!",
    },
    password: {
      placeholder: "Nhập password",
      message: "Vui lòng nhập password!",
      atleast: "Mật khẩu tối thiểu tám ký tự",
      character:
        "Mật khẩu chứa ít nhất một chữ cái viết hoa, một chữ cái viết thường, một số và một ký tự đặc biệt",
    },
  },
  PERMISSIONS: {
    ADD_EMPLOYEE: "add_employee",
    CANCEL_BILL: "cancel_bill",
    CHANGE_PERMISSION: "change_permission",
    CREATE_BILL: "create_bill",
    DETAIL_BILL: "detail_bill",
    EDIT_COD: "edit_cod",
    EDIT_GENERAL_BILL: "edit_general_bill",
    PRINT_BILL: "print_bill",
    REQUEST_RETURN: "request_return",
    UPDATE_BILL: "update_bill",
    REQUEST_REDELIVERY: "request_redelivery",
  },
  SUBMIT_BUTTON: {
    size: "large",
    type: "primary",
    key: "submit",
    htmlType: "submit",
  },
};
