import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

/*--------------------------REDUCERS------------------------------------------*/
import ordersReducer from "./ordersReducer";
import statesReducer from "./statesReducer";
import authReducer from "./auth/authReducer";
import geoReducer from "./geo/geoReducer";
import dashboardReducer from "./dashboard/dashboardReducer";
import customerReducer from "./customer/customerReducer";
import postageReducer from "./postage/postageReducer";
import codReducer from "./cod/codReducer";
import billReducer from "./bill/billReducer";
import bankReducer from "./bank/bankReducer";
import userReducer from "./user/userReducer";
import promotionReducer from "./promotion/promotionReducer";
import isLoadReducer from "./isLoadReducer/isLoadReducer";

const createRootReducer = (history) =>
  combineReducers({
    ordersReducer,
    statesReducer,
    authReducer,
    geoReducer,
    customerReducer,
    promotionReducer,
    userReducer,
    codReducer,
    bankReducer,
    billReducer,
    dashboardReducer,
    postageReducer,
    isLoadReducer,
    router: connectRouter(history),
  });

export default createRootReducer;
