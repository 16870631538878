import { notification } from "antd";
import _ from "lodash";
import moment from "moment";
import Constants from "constants/variables";

const openNotification = (type, message, duration, description) => {
  let key = moment.unix();
  notification.open({
    message: `${message}`,
    duration: duration || duration === null ? duration : 2,
    description: description,
    key,
    onClick: () => closeNotification(key),
    onClose: () => closeNotification(key),
    placement: "topRight",
    className: `notication-${type}-style`,
  });
};

function closeNotification(key) {
  notification.close(key);
}

let notifySucess = _.debounce(
  function (message, duration, description) {
    if (message) openNotification("success", message, duration, description);
  },
  Constants.UPDATE_TIME_OUT,
  {
    leading: "true",
    trailing: "false",
  }
);

let notifyError = _.debounce(
  function (message, duration, description) {
    if (message) openNotification("error", message, duration, description);
  },
  Constants.UPDATE_TIME_OUT,
  {
    leading: "true",
    trailing: "false",
  }
);

export { notifySucess };
export { notifyError };
