import * as type from "../../actions/actionType";

const initState = {};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case type.START_RESET_PASSWORD:
      return {
        ...state,
        isLoading: true,
        message: null,
        success: null,
      };
    case type.SUCCESS_RESET_PASSWORD:
      return {
        ...state,
        success: action.success,
        dataReset: action.dataUpdate,
        paramsSignup: action.paramsSignup,
        isLoading: false,
        message: action.message ? action.message : null,
        redirectTo: "/login",
      };
    case type.FAILED_RESET_PASSWORD:
      return {
        ...state,
        success: false,
        dataReset: null,
        isLoading: false,
        message: action.message ? action.message : null,
      };

    case type.START_UPDATE_PARTNER:
      return {
        ...state,
        isLoading: true,
        message: null,
        redirectTo: null,
      };
    case type.SUCCESS_UPDATE_PARTNER:
      return {
        ...state,
        success: action.success,
        dataUpdate: action.dataUpdate,
        phone_request: action.phone_request,
        isLoading: false,
        message: action.message ? action.message : null,
        redirectTo: "/login",
      };

    case type.FAILED_UPDATE_PARTNER:
      return {
        ...state,
        success: false,
        dataUpdate: null,
        isLoading: false,
        message: action.message ? action.message : null,
      };

    case type.START_CONFIRM_OTP:
      return {
        ...state,
        isLoading: true,
        message: null,
        success: null,
      };

    case type.SUCCESS_CONFIRM_OTP:
      return {
        ...state,
        success: action.success,
        dataConfirm: action.dataConfirm,
        isLoading: false,
        message: action.message ? action.message : null,
      };
    case type.FAILED_CONFIRM_OTP:
      return {
        ...state,
        success: false,
        dataConfirm: null,
        isLoading: false,
        message: action.message ? action.message : null,
      };
    case type.START_FETCH_OTP_RESET_PASSWORD:
      return {
        ...state,
        isLoading: true,
        message: null,
        success: null,
      };
    case type.SUCCESS_FETCH_OTP_RESET_PASSWORD:
      return {
        ...state,
        success: action.success,
        dataOTP: action.dataOTP,
        phone_request: action.phone_request,
        isLoading: false,
        message: action.message ? action.message : null,
      };
    case type.FAILED_FETCH_OTP_RESET_PASSWORD:
      return {
        ...state,
        isLoading: false,
        dataOTP: null,
        message: action.message ? action.message : null,
        success: false,
      };
    case type.START_FETCH_OTP:
      return {
        ...state,
        isLoading: true,
        message: null,
        success: null,
      };
    case type.SUCCESS_FETCH_OTP:
      return {
        ...state,
        success: action.success,
        dataOTP: action.dataOTP,
        phone_request: action.phone_request,
        isLoading: false,
        message: action.message ? action.message : null,
      };
    case type.FAILED_FETCH_OTP:
      return {
        ...state,
        isLoading: false,
        dataOTP: null,
        message: action.message ? action.message : null,
        success: false,
      };

    case type.START_REGISTER_EMAIL:
      return {
        ...state,
        isLoading: true,
        message: null,
        redirectTo: null,
      };
    case type.FAILED_REGISTER_EMAIL:
      return {
        ...state,
        redirectTo: null,
        token: null,
        dataRegister: null,
        isLoading: false,
        paramsSignup: null,
        success: action.success ? action.success : false,
        message: action.message ? action.message : null,
      };
    case type.SUCCESS_REGISTER_EMAIL:
      return {
        ...state,
        redirectTo: "/login",
        token: null,
        dataRegister: action.dataRegister,
        isLoading: false,
        paramsSignup: action.paramsSignup,
        success: action.success,
        message: action.message,
      };

    case type.START_LOGIN:
      return {
        ...state,
        isLoading: true,
        message: null,
        data: [],
        redirectTo: null,
        dataLogin: null,
      };
    case type.SUCCESS_LOGIN:
      return {
        ...state,
        redirectTo: action.success ? "/dashboard" : null,
        authenticate: action.success,
        token: action.success ? action.dataLogin.token : null,
        dataLogin: action.dataLogin,
        data: action.data.partner_info,
        errorCode: action.success,
        isLoading: false,
        message: action.message ? action.message : null,
      };
    case type.FAILED_LOGIN:
      return {
        ...state,
        redirectTo: null,
        authenticate: false,
        token: null,
        data: [],
        dataLogin: null,
        errorCode: false,
        isLoading: false,
        message: action.message ? action.message : null,
      };

    case type.START_GET_CURRENT_USER:
      return {
        ...state,
      };
    case type.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        redirectTo: !action.success ? "/login" : null,
        authenticate: action.success,
        dataLogin: action.dataUser,
        errorCode: action.success,
        isLoading: false,
        message: action.message ? action.message : null,
      };
    default:
      return state;
  }
};

export default authReducer;
