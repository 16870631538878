export const APP_URL =
  window._env_.REACT_APP_STAGE === "production"
    ? "https://crm.ntlogistics.vn/"
    : "https://crmdev.ntlogistics.vn/";

export const API_CARE =
  window._env_.REACT_APP_STAGE === "production"
    ? "https://efmwsdev.ntlogistics.vn/"
    : "https://efmwsdev.ntlogistics.vn/";

export const API_HRM =
  window._env_.REACT_APP_STAGE === "production"
    ? "https://hrmwsdev.ntx.com.vn/"
    : "https://hrmwsdev.ntx.com.vn/";

export const API_EFM =
  window._env_.REACT_APP_STAGE === "production"
    ? "https://efmwsdev.ntlogistics.vn/"
    : "https://efmwsdev.ntlogistics.vn/";

export const API_URL =
  window._env_.REACT_APP_STAGE === "production"
    ? "https://bws.ntlogistics.vn/"
    : window._env_.REACT_APP_STAGE === "development"
    ? "https://bwsdev.ntlogistics.vn/"
    : "http://localhost:8080/";

export const CDN_URL =
  window._env_.REACT_APP_STAGE === "production"
    ? "https://cdn.ntlogistics.vn/"
    : "https://cdndev.ntlogistics.vn/";
export const CDN_TOKEN =
  "FzQScmQ7LXswQdH6KtcgTTJUBguYK4";

export const CDN_URL_V2 =
  window._env_.REACT_APP_STAGE === "production"
    ? "https://cdn2.ntlogistics.vn/"
    : "https://cdndev.ntlogistics.vn/";
export const CDN_TOKEN_V2 = "FzQScmQ7LXswQdH6KtcgTTJUBguYK4";

export const NTX_URL =
  window._env_.REACT_APP_STAGE === "production"
    ? "https://ntx.com.vn/"
    : "https://webdev.ntx.com.vn/";

export const SANDBOX_URL =
  window._env_.REACT_APP_STAGE === "production"
    ? "https://apiws.ntlogistics.vn/"
    : "https://apisandbox.ntlogistics.vn/";

export const NTL_TICKET_URL =
  window._env_.REACT_APP_STAGE === "production"
    ? "https://ticketws.ntlogistics.vn/"
    : "https://ticketwsdev.ntlogistics.vn/";

export const API_NTL_NOTIFY =
  window._env_.REACT_APP_STAGE === "production"
    ? ""
    : "https://notiwsdev.ntlogistics.vn/v1";

export const APP_ID = 2;

export const FB_EFM_TOKEN = "ot#b{9a&ZlHbfbCU5&4)61mlroH3&gd";
