export const isLoadReducer = (
  state = {
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case "IS_START":
      return {
        isProgress: true
      };
      case "IS_DONE":
        return {
          isProgress: false
        };
    default:
      return state;
  }
};

export default isLoadReducer;